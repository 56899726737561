import React, { Component } from 'react';
import {Modal, Select, Col} from 'antd';
import PropTypes from 'prop-types';
import lodash from 'lodash';

const propsTypes = {
  salaryElements: PropTypes.arrayOf(
    PropTypes.shape({
      id                  : PropTypes.number.isRequired,
      code                : PropTypes.string.isRequired,
      name                : PropTypes.string.isRequired,
    })
  ),
  selectedSalaryElement: PropTypes.number.isRequired,
  selectedTypePoint:PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  confirmLoading: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isEditting: PropTypes.bool.isRequired,
  handleChangeSelectSalaryElement: PropTypes.func.isRequired,
  handleChangeSelectTypePoint: PropTypes.func.isRequired,
}

//======================= Select ===================================//
const Option = Select.Option;
// function handleChange(value) {
//   console.log(`selected ${value}`);
// }
function handleBlur() {
  // console.log('blur');
}
function handleFocus() {
  // console.log('focus');
}
//===================== End Select ================================//

class SalaryElementModal extends Component {
  render() {
    const {
      visible, 
      confirmLoading, 
      handleOk, 
      handleCancel, 
      salaryElements, 
      isEditting, 
      handleChangeSelectSalaryElement, 
      handleChangeSelectTypePoint,
      selectedSalaryElement,
      selectedTypePoint,
    } = this.props;
    
    return (
      <Modal title={isEditting ? 'Sửa liên kết thành phần lương' : 'Thêm liên kết thành phần lương'}
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
          >
            <div className="padding-bottom-15">
            
              <Col className="gutter-row" span={12}>
                <div className="gutter-box margin-left-7">
                  <label className="text-title-field col-md-12">
                  Thành phần lương <span className="text-danger">*</span>
                </label>
                {selectedSalaryElement !== 0 &&
                    <Select
                    showSearch
                    style={{ width: 200 }}
                    className="width-100-per"
                    defaultValue={selectedSalaryElement}
                    onChange={handleChangeSelectSalaryElement}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    placeholder="Thành phần lương"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                  {salaryElements && salaryElements.map(s => 
                    <Option key={s.id+s.code} value={s.id}>{s.name}</Option>
                  )}
                  </Select>
                  }
                  {selectedSalaryElement === 0 &&
                    <Select
                      showSearch
                      className="width-100-per"
                      placeholder="Thành phần lương"
                      className="width-100-per"
                      optionFilterProp="children"
                      onChange={handleChangeSelectSalaryElement}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {salaryElements && salaryElements.map(s => 
                        <Option key={s.id+s.code} value={s.id}>{s.name}</Option>
                      )}
                    </Select>
                  }
                </div>
              </Col>
              <Col className="gutter-row" span={12}>
                <div className="gutter-box margin-left-7">
                <label className="text-title-field col-md-12">
                  Loại thưởng <span className="text-danger">*</span>
                </label>
                {selectedTypePoint !== 0 && 
                <Select
                  style={{ width: 200 }}
                  defaultValue={selectedTypePoint}
                  className="width-100-per"
                  placeholder="Loại thưởng"
                  optionFilterProp="children"
                  onChange={handleChangeSelectTypePoint}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value={0}>Vui lòng chọn</Option>
                  <Option value={1}>Thưởng doanh số TCH</Option>
                  <Option value={2}>Thưởng dịch vụ TCH</Option>
                  <Option value={3}>Thưởng doanh số thuê mặt bằng</Option>
                  <Option value={4}>Thưởng doanh số Tenren</Option>
                  <Option value={5}>Thưởng doanh số Signature</Option>
                  <Option value={6}>Thưởng dịch vụ Signature</Option>
                </Select>
                }
                {selectedTypePoint === 0 &&
                    <Select
                      className="width-100-per"
                      placeholder="Loại thưởng"
                      optionFilterProp="children"
                      onChange={handleChangeSelectTypePoint}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      filterOption={(input, option) => {
                        let optionData = option.props.children;
                        if (typeof option !== 'string') {
                          optionData = optionData.join('');
                        }
                        return optionData.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }}
                    >
                  <Option value={0}>Vui lòng chọn</Option>
                  <Option value={1}>Thưởng doanh số TCH</Option>
                  <Option value={2}>Thưởng dịch vụ TCH</Option>
                  <Option value={3}>Thưởng doanh số thuê mặt bằng</Option>
                  <Option value={4}>Thưởng doanh số Tenren</Option>
                  <Option value={5}>Thưởng doanh số Signature</Option>
                  <Option value={6}>Thưởng dịch vụ Signature</Option>
                    </Select>
                  }
                </div>
              </Col>
            </div>
            <br />
            <div style={{height:100}}></div>
          </Modal>
    );
  }
}

SalaryElementModal.propTypes = propsTypes;

export default SalaryElementModal;