const config = {
  pathAdmin: 'site/',
  backend_url: 'http://localhost:3000/',
  pretty_url: 'http://loc.hara_oes.com:3000/',
  hraccount_login: {
    orgid             : '200000000094',
    response_mode     : 'form_post',
    url_authorize     : 'https://accounts.hara.vn/connect/authorize',
    url_connect_token : 'https://accounts.hara.vn/connect/token',
    url_get_user_info : 'https://accounts.hara.vn/connect/userinfo',
    grant_type        : 'authorization_code',
    nonce             : 'kcjqhdltd',
    response_type     : 'code id_token',
    get_code_callback : '/api/authentication/hr',
    clientID          : 'a94065f20c92d3d6cf4859c4264c61a4',
    scope             : 'offline_access openid profile hac_api.read_users hr_api ta_api email org userinfo',
  },
  get buildLoginHrUrlCallBack() {
    return this.pretty_url + 'api/authentication/hr';
  },
  get buildLoginHrUrl() {
    let objQuery = {
      response_mode: 'form_post',
      response_type: this.hraccount_login.response_type,
      scope: encodeURIComponent(this.hraccount_login.scope),
      client_id: encodeURIComponent(this.hraccount_login.clientID),
      redirect_uri: encodeURIComponent(this.buildLoginHrUrlCallBack),
      nonce: this.hraccount_login.nonce
    };
    let query = Object.keys(objQuery).map(key => key + '=' + objQuery[key]).join('&');
    let url = `${this.hraccount_login.url_authorize}?${query}`;
    return url;
  }
};

const configStaging = {
  pathAdmin: 'site/',
  backend_url: 'https://tinhthuongtch-onapp.sku.vn/',
  pretty_url: 'https://tinhthuongtch-onapp.sku.vn/',
  hraccount_login: {
    orgid             : '200000000094',
    response_mode     : 'form_post',
    url_authorize     : 'https://accounts.hara.vn/connect/authorize',
    url_connect_token : 'https://accounts.hara.vn/connect/token',
    url_get_user_info : 'https://accounts.hara.vn/connect/userinfo',
    grant_type        : 'authorization_code',
    nonce             : 'kcjqhdltd',
    response_type     : 'code id_token',
    get_code_callback : '/api/authentication/hr',
    clientID          : '68536edfec7f5f9c14e628d10766d6ed',
    scope             : 'offline_access openid profile hac_api.read_users hr_api ta_api email org userinfo',
  },
  get buildLoginHrUrlCallBack() {
    return this.pretty_url + 'api/authentication/hr';
  },
  get buildLoginHrUrl() {
    let objQuery = {
      response_mode: 'form_post',
      response_type: this.hraccount_login.response_type,
      scope: encodeURIComponent(this.hraccount_login.scope),
      client_id: encodeURIComponent(this.hraccount_login.clientID),
      redirect_uri: encodeURIComponent(this.buildLoginHrUrlCallBack),
      nonce: this.hraccount_login.nonce
    };
    let query = Object.keys(objQuery).map(key => key + '=' + objQuery[key]).join('&');
    let url = `${this.hraccount_login.url_authorize}?${query}`;
    return url;
  }
};

const configPro = {
  pathAdmin: 'site/',
  backend_url: 'https://tinhthuongtch.onapp.haravan.com/',
  pretty_url: 'https://tinhthuongtch.onapp.haravan.com/',
  hraccount_login: {
    orgid             : '200000000094',
    response_mode     : 'form_post',
    url_authorize     : 'https://accounts.haravan.com/connect/authorize',
    url_connect_token : 'https://accounts.haravan.com/connect/token',
    url_get_user_info : 'https://accounts.haravan.com/connect/userinfo',
    grant_type        : 'authorization_code',
    nonce             : 'kcjqhdltd',
    response_type     : 'code id_token',
    get_code_callback : '/api/authentication/hr',
    clientID          : '8ab8739844e2e2cb1133013e336434de',
    scope             : 'offline_access openid profile hac_api.read_users hr_api ta_api email org userinfo',
  },
  get buildLoginHrUrlCallBack() {
    return this.pretty_url + 'api/authentication/hr';
  },
  get buildLoginHrUrl() {
    let objQuery = {
      response_mode: 'form_post',
      response_type: this.hraccount_login.response_type,
      scope: encodeURIComponent(this.hraccount_login.scope),
      client_id: encodeURIComponent(this.hraccount_login.clientID),
      redirect_uri: encodeURIComponent(this.buildLoginHrUrlCallBack),
      nonce: this.hraccount_login.nonce
    };
    let query = Object.keys(objQuery).map(key => key + '=' + objQuery[key]).join('&');
    let url = `${this.hraccount_login.url_authorize}?${query}`;
    return url;
  }
};

let result = config;
const hostname = window && window.location && window.location.hostname;
if (hostname.indexOf('tinhthuongtch.onapp.haravan.com') > -1) {
  result = configPro;
} else if (hostname.indexOf('tinhthuongtch-onapp.sku.vn') > -1) {
  result = configStaging;
} else {
  result = config;
}
export default result;