import BaseModel from 'model/base.js'
const apiUrl = 'api';
const DepartmentExcepModel = {
  getListDepartmentExcep: (sucCallback, errCallback, finCallback) => {
    let url = 'api/hara-work/jobtitles?isGetAll=true'
    BaseModel.get(url, null, function(data) {
      sucCallback(data.data);
    },
    err => errCallback(err),
    () => finCallback())
  },

  getList: (filter,sucCallback, errCallback, finCallback) => {
    let url = 'api/departmentexcep/getlist'
    BaseModel.get(url, filter, function(data) {
      sucCallback(data.data);
    },
    err => errCallback(err),
    () => finCallback())
  },
  AddRaw: (query, data, sucCallback, errCallback, finCallback) => {
    let url = `${apiUrl}/departmentexcep/addraw`;
    BaseModel.post(url,data, function (data) {
      sucCallback(data.data);
    },
      err => {
        errCallback(err)
      },
      () => finCallback())
  },
  Add: (data, sucCallback, errCallback, finCallback) => {
    let url = `${apiUrl}/departmentexcep/add`;
    BaseModel.post(
      url, 
      data, 
      res => {
        sucCallback(res)
      }, 
      err => {
        errCallback(err)
      },
      () => finCallback()
    )
  },
  Update: (data, sucCallback, errCallback, finCallback) => {
    let url = `${apiUrl}/departmentexcep/update`;
    BaseModel.put(url, data, function(res) {
      sucCallback(res);
    },
    err => errCallback(err),
    () => finCallback())
  },
  Delete: (departmentexcepId, sucCallback, errCallback, finCallback) => {
    let url = `${apiUrl}/departmentexcep/delete`;
    BaseModel.delete(url,{_id:departmentexcepId}, function(res) {
      sucCallback(res);
    },
    err => errCallback(err),
    () => finCallback())
  }
}

export default DepartmentExcepModel;