import Salary from 'modules/admin/salary-element/container/index'
import config from 'config'
const routes = [
    {
      path: '/' + config.pathAdmin + 'salary',
      component: Salary,
      option: {
        a: 'b'
      }
    }
];

export default routes;