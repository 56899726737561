import LogOut from 'modules/admin/log-out/container/index'
import config from 'config'
const routes = [
    {
      path: '/' + config.pathAdmin + 'log-out',
      component: LogOut,
      option: {
        a: 'b'
      }
    }
];

export default routes;