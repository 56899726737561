/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Table, Layout, Switch,Modal,Tooltip,Icon,Divider,Col,Button } from 'antd';
import {actSalaryList} from '../../../../actions/admin/salaries';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AdminLayout from 'modules/admin/admin-layout.js';
import AlertBox from '../../../../component/alertbox/alertbox';
import AdminContentHeader from 'modules/admin/admin-content-header.js';
import SalaryModel from '../../../../model/setting-salary-element';
import SalaryElementModal from '../salaryelementModal/salaryelementModal';
import _ from 'lodash';


const confirm = Modal.confirm;
class SalaryIndexs extends Component {
  //===================== Start Table =============================//
  state = {
    salaries: null,
    ModalText: 'Content of the modal',
    visible: false,
    typeAlert: '',
    msgAlert: '',
    confirmLoading: false,
    isEditting:false,
    selectedSalaryElement:0,
    selectedTypePoint:0,
    settingSalaryElements:[],
    salaryElements:[],
    id_editing:null,
  }

  handleChangeSelectSalaryElement = id => {
    this.setState({
      selectedSalaryElement: id
    })
  }

  handleChangeSelectTypePoint = id => {
    this.setState({
      selectedTypePoint: id
    })
  }

  listSalary = function(query) {
    return new Promise((resolve, reject) => {
      SalaryModel.getList(query, (dataSalaries) => {
        resolve(dataSalaries);
      }, (error) =>{
        reject(error);
      });
    });
  }

  async componentDidMount() {
    this.getSalaryElements();
  }

  handleOk = () => {
    const {id_editing, selectedSalaryElement, selectedTypePoint } = this.state;
    if(selectedSalaryElement==null||selectedSalaryElement==0){
      this.setState({
        visible:true,
        alertVisitble: true,
        msgAlert: 'Vui lòng chọn thành phần lương.',
        typeAlert: 'error'
      },()=>setTimeout(() => this.setState({
        alertVisitble: false,
      }), 3000));
      return;
    }

    if(selectedTypePoint==null||selectedTypePoint==0){
      this.setState({
        visible:true,
        alertVisitble: true,
        msgAlert: 'Vui lòng chọn loại thưởng.',
        typeAlert: 'error'
      },()=>setTimeout(() => this.setState({
        alertVisitble: false,
      }), 3000));
      return;
    }
    
    let object={_id:id_editing, salaryelement_id:selectedSalaryElement,type_point:selectedTypePoint}
    if (!this.state.isEditting) {
      object._id=null;
      this.postSettingSalaryElement(object);
    } else {
      this.putSettingSalaryElement(object);
    }

    this.setState({
      ModalText: 'The modal will be closed after success',
      confirmLoading: true,
    });
  }
  postSettingSalaryElement = settingSalaryElementModel => {
    let _this = this;
    SalaryModel.postSettingSalaryElement(

      settingSalaryElementModel,
      data => {
        _this.setState({
          visible: false,
          selectedSalaryElement:0,
          selectedTypePoint:0,
          alertVisitble: true,
          msgAlert: 'Thêm liên kết thành phần lương với loại thưởng thành công.',
          typeAlert: 'success'
        });
      },
      err => {
        _this.setState({
          alertVisitble: true,
          msgAlert: `Thêm liên kết thành phần lương với loại thưởng thất bại. Chi tiết: ${err.response.data.message.join()}`,
          typeAlert: 'error'
        })
      },
      () => {
        _this.setState({
          confirmLoading: false,
        });
        _this.getSettingSalaryElements();
      }
    )
  }

  handleSaveIdEditing = id =>{
    this.setState({id_editing : id})
  }

  putSettingSalaryElement = settingSalaryElementModel => {
    let _this = this;
    SalaryModel.putSettingSalaryElement(
      settingSalaryElementModel,
      data => {
        this.setState({
          visible: false,
          selectedSalaryElement:0,
          selectedTypePoint:0,
          alertVisitble: true,
          msgAlert: 'Sửa liên kết thành phần lương với loại thưởng thành công',
          typeAlert: 'success'
        })
      },
      err => {
        _this.setState({
          alertVisitble: true,
          msgAlert: `Sửa liên kết thành phần lương với loại thưởng thất bại. Chi tiết: ${err.response.data.message.join()}`,
          typeAlert: 'error'
        })
      },
      () => {
        _this.setState({
          confirmLoading: false
        });
        _this.getSettingSalaryElements();
      }
    )
  }

  deleteSettingSalaryElement = id => {
    let _this = this;
    SalaryModel.deleteSettingSalaryElement(id, data => {
      _this.setState({
        alertVisitble: true,
        msgAlert: 'Xóa liên kết thành phần lương với loại thưởng thành công',
        typeAlert: 'success'
      })
    },
      err => {
        _this.setState({
          alertVisitble: true,
          msgAlert: `Xóa liên kết thành phần lương với loại thưởng thất bại. Chi tiết: ${err}`,
          typeAlert: 'error'
        })
      },
      () => {
        _this.getSettingSalaryElements();
      })
  }

  getSettingSalaryElements = () => {
    let _this = this;
    _this.setState({
      isLoadingData: true
    });
    SalaryModel.getListSettingSalaryElement(null,function (data) {
      var dataRe = data.items.map(a => {
        var salaryElement = _this.state.salaryElements.filter(b => b.id == a.salaryelement_id)[0]
        a.salaryElementName = salaryElement ? salaryElement.name : '';
        a.salaryelement_code = salaryElement ? salaryElement.code : '';
        return a;
      })
      _this.setState({ settingSalaryElements: dataRe ,isLoadingData:false });
    },
      err => {
        _this.setState({
          alertVisitble: true,
          typeAlert: 'error',
          msgAlert: `${err}`
        })
      },
    () => {
      if (_this.state.alertVisitble === true) {
        setTimeout(() => _this.setState({
          alertVisitble: false
        }), 3000)
      }
      _this.setState({
        isLoadingData: false
      })
    });
  }

  getSalaryElements = () => {
    let _this = this;
    _this.setState({
      isLoadingData: true
    });
    SalaryModel.getList(null,function (data) {
      _this.setState({ salaryElements: data.items,isLoadingData:false });
      _this.getSettingSalaryElements();
    },
      err => {
        _this.setState({
          alertVisitble: true,
          typeAlert: 'error',
          msgAlert: `${err}`
        })
      },
    () => {
      if (_this.state.alertVisitble === true) {
        setTimeout(() => _this.setState({
          alertVisitble: false
        }), 3000)
      }
      _this.setState({
        isLoadingData: false
      })
    });
  }

  handleCancel = () => {
    console.log('Clicked cancel button');
    this.setState({
      visible: false,
      selectedSalaryElement:0,
      selectedTypePoint:0,
    });
  }

  handleSwitchChange(record) {
    let chooseData = '';
    let lstSalary = this.state.salaries;
    let objIndex = lstSalary.findIndex(salary => salary.id === record.id);
    lstSalary[objIndex].isSwitch = !lstSalary[objIndex].isSwitch;

    if (lstSalary[objIndex].isSwitch) {
      chooseData = {id: record.id};
      SalaryModel.chooseSalary(chooseData, true, (data) => {
        if (!data) {
          return false;
        }
        lstSalary.forEach(salary => {
            if (salary.id !== record.id) {
              salary.isSwitch = false;
            }
        });
        this.setState({salaries: lstSalary, visible: true, typeAlert: 'success', msgAlert: 'Success'});
        setTimeout(() => {
          this.setState({visible: false});
        }, 3000);
      }, (error) => {
        this.setState({visible: true, typeAlert: 'error', msgAlert: 'Error'});
        setTimeout(() => {
          this.setState({visible: false});
        }, 3000);
      });
    } else {
      SalaryModel.chooseSalary(chooseData, false, (data) => {
        this.setState({salaries: lstSalary, visible: true, typeAlert: 'success', msgAlert: 'Success'});
        setTimeout(() => {
          this.setState({visible: false});
        }, 3000);
      }, (error) => {
        this.setState({visible: true, typeAlert: 'error', msgAlert: 'Error'});
        setTimeout(() => {
          this.setState({visible: false});
        }, 3000);
      });
    }
  }
  showConfirm=(_id)=> {
    var _this=this;
         confirm({
           content: "Bạn có thực sự muốn xóa dòng này ?",
           title:"Thông báo",
           onOk() {
             _this.deleteSettingSalaryElement(_id);
           },
           onCancel() {
             //console.log('Cancel');
           },
         });
   }

   showModal = e => {
    if (e) {
      this.setState({
        isEditting: false
      })
    }
    this.setState({
      visible: true,
    });
  }
  changeIsEdittingStatus = () => {
    this.setState({
      isEditting: true
    })
  }

  columns() {
    let data = [{
      title: 'Tên',
      dataIndex: 'salaryElementName',
      key: 'salaryElementName',
      width:'40%'
    }, {
      title: 'Mã',
      width:'20%',
      dataIndex: 'salaryelement_code',
      key: 'salaryelement_code',
      render: text => <a>{text}</a>,
    },{
      width:'30%',
      title: 'Loại thưởng',
      dataIndex: 'type_point',
      key: 'type_point',
      render: text => <a>{text==1?'Thưởng doanh số TCH':text==2?"Thưởng dịch vụ TCH": text==3?'Thưởng doanh số thuê mặt bằng':text==4?'Thưởng doanh số Tenren': text==5?'Thưởng doanh số Signature':'Thưởng dịch vụ Signature'}</a>,
    }, {
      title: '',
      key: 'action',
      width: '5%',
      render: (text, record) => (
        <span className="text-center">
          <Tooltip placement="left" title="Xoá">
            <span
              onClick={() => this.showConfirm(record._id)}
              style={{ color: '#1890ff', cursor: 'pointer' }}
            >
              <Icon type="delete" />
            </span>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip placement="left" title="Chỉnh sửa">
            <span
              onClick={() => {
                this.showModal(true);
                this.changeIsEdittingStatus();
                this.handleChangeSelectSalaryElement(record.salaryelement_id);
                this.handleChangeSelectTypePoint(record.type_point);
                this.handleSaveIdEditing(record._id);
              }}
              style={{ color: '#1890ff', cursor: 'pointer' }}
            >
              <Icon type="edit" theme="twoTone" />
            </span>
          </Tooltip>

        </span>
      ),
    }];
    return data;
  }
  
  //====================== End Table ===============================//

  render() {
    return (
      <AdminLayout>
        <Layout>
          <div className="app-heading">
            <AdminContentHeader text="Thành phần lương" />
            <Col className="gutter-row" span={12}>
                  <div className="gutter-box text-right">
                    <Button onClick={this.showModal} type="primary" ghost><Icon type="plus-circle" theme="twoTone" /> Thêm</Button>
                  </div>
                </Col>
          </div>
          <div className="app-content">
            <div>
              <Table bordered size="middle" columns={this.columns()} dataSource={this.state.settingSalaryElements} pagination={false} rowKey='id' />
            </div>
            <div className="clear"></div>
          </div>
          <SalaryElementModal
                salaryElements={this.state.salaryElements}
                visible={this.state.visible}
                confirmLoading={this.state.confirmLoading}
                handleOk={this.handleOk}
                handleCancel={this.handleCancel}
                isEditting={this.state.isEditting}
                handleChangeSelectSalaryElement={this.handleChangeSelectSalaryElement}
                handleChangeSelectTypePoint={this.handleChangeSelectTypePoint}
                selectedSalaryElement={this.state.selectedSalaryElement}
                selectedTypePoint={this.state.selectedTypePoint}
              />
          {
            this.state.alertVisitble ? (
              <AlertBox
                message={this.state.msgAlert}
                type={this.state.typeAlert}
              />
            ) : null
          }
        </Layout>
      </AdminLayout>
      
    );
  }
}

const mapStateToProps = state => ({
  store: state.salaries,
});
const mapDispatchToProps = dispatch => ({
  getStoreList: bindActionCreators(actSalaryList, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SalaryIndexs);
