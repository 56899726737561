import React, { Component } from 'react';
import {Modal, Select, Col} from 'antd';
import PropTypes from 'prop-types';
import lodash from 'lodash';

const propsTypes = {
  JobtitlelevelModal: PropTypes.arrayOf(
    PropTypes.shape({
      jobtile_id: PropTypes.number.isRequired,
      level: PropTypes.number.isRequired,
      brand: PropTypes.string.isRequired
    })
  ).isRequired, // store nhan vao phai la array .isRequied la yeu cau phai co
  jobtitle: PropTypes.arrayOf(
    PropTypes.shape({
      id                  : PropTypes.number.isRequired,
      code                : PropTypes.string.isRequired,
      name                : PropTypes.string.isRequired,
    })
  ),
  selectedJobtitle: PropTypes.number.isRequired,
  selectedBrand:PropTypes.number.isRequired,
  selectedTypeBonus:PropTypes.number.isRequired,
  inputLevel:PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  confirmLoading: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isEditting: PropTypes.bool.isRequired,
  handleChangeSelectJobtitle: PropTypes.func.isRequired,
  handleChangeSelectBrand: PropTypes.func.isRequired,
  handlechangeInputLevel: PropTypes.func.isRequired,
  handleSaveIdEditing: PropTypes.func.isRequired,
  handleSelectedTypeBonus:PropTypes.func.isRequired,
}

//======================= Select ===================================//
const Option = Select.Option;
// function handleChange(value) {
//   console.log(`selected ${value}`);
// }
function handleBlur() {
  // console.log('blur');
}
function handleFocus() {
  // console.log('focus');
}
//===================== End Select ================================//

class JobtitlelevelModal extends Component {
  render() {
    const {
      visible, 
      confirmLoading, 
      handleOk, 
      handleCancel, 
      jobtitle, 
      isEditting, 
      handleChangeSelectJobtitle, 
      handleChangeSelectBrand,
      handlechangeInputLevel, 
      selectedJobtitle,
      selectedBrand,
      inputLevel,
      selectedTypeBonus,
      handleSelectedTypeBonus,
    } = this.props;
    
    return (
      <Modal title={isEditting ? 'Sửa Trọng số' : 'Thêm Trọng số'}
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
          >
            <div className="padding-bottom-15">
            
              <Col className="gutter-row" span={12}>
                <div className="gutter-box margin-left-7">
                  <label className="text-title-field col-md-12">
                  Chức danh <span className="text-danger">*</span>
                </label>
                {selectedJobtitle !== 0 &&
                    <Select
                    showSearch
                    style={{ width: 200 }}
                    className="width-100-per"
                    defaultValue={selectedJobtitle}
                    onChange={handleChangeSelectJobtitle}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    placeholder="Select a person"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                  {jobtitle && jobtitle.map(s => 
                    <Option key={s.id} value={s.id}>{s.name}</Option>
                  )}
                  </Select>
                  }
                  {selectedJobtitle === 0 &&
                    <Select
                      showSearch
                      className="width-100-per"
                      placeholder="Chức danh"
                      className="width-100-per"
                      optionFilterProp="children"
                      onChange={handleChangeSelectJobtitle}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {jobtitle && jobtitle.map(s => 
                        <Option key={s.id} value={s.id}>{s.name}</Option>
                      )}
                    </Select>
                  }
                </div>
              </Col>
              <Col className="gutter-row" span={12}>
                <div className="gutter-box margin-left-7">
                <label className="text-title-field col-md-12">
                  Trọng số <span className="text-danger">*</span>
                </label>
                <input name="level" value={inputLevel} type='number' style={{padding:10}} onChange={handlechangeInputLevel} placeholder="Nhập trọng số" className="width-100-per ant-select-selection
            ant-select-selection--single" ></input>
                </div>
              </Col>
              <Col className="gutter-row" span={12}>
                <div className="gutter-box margin-left-7">
                <label className="text-title-field col-md-12">
                  Nhánh <span className="text-danger">*</span>
                </label>
                {selectedBrand !== 0 && 
                <Select
                  style={{ width: 200 }}
                  defaultValue={selectedBrand}
                  className="width-100-per"
                  placeholder="Select a person"
                  optionFilterProp="children"
                  onChange={handleChangeSelectBrand}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value={0}>Vui lòng chọn</Option>
                  <Option value={'TCH'}>TCH</Option>
                  <Option value={'TR'}>TR</Option>
                </Select>
                }
                {selectedBrand === 0 &&
                    <Select
                      showSearch
                      className="width-100-per"
                      placeholder="Nhánh"
                      optionFilterProp="children"
                      onChange={handleChangeSelectBrand}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        <Option value={0}>Vui lòng chọn</Option>
                        <Option value={'TCH'}>TCH</Option>
                        <Option value={'TR'}>TR</Option>
                    </Select>
                  }
                </div>
              </Col>
              <Col className="gutter-row" span={12}>
                <div className="gutter-box margin-left-7">
                <label className="text-title-field col-md-12">
                  Loại thưởng <span className="text-danger">*</span>
                </label>
                { selectedTypeBonus !== 0 && 
                <Select
                  style={{ width: 200 }}
                  defaultValue={selectedTypeBonus}
                  className="width-100-per"
                  placeholder="Chọn loại thưởng"
                  optionFilterProp="children"
                  onChange={handleSelectedTypeBonus}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value={0}>Vui lòng chọn</Option>
                  <Option value={1}>Thưởng doanh số</Option>
                  <Option value={2}>Thưởng dịch vụ</Option>
                  <Option value={3}>Thưởng thuê mặt bằng</Option>
                  <Option value={4}>Thưởng doanh số Signature</Option>
                  <Option value={5}>Thưởng dịch vụ Signature</Option>
                </Select>
                }
                {selectedTypeBonus === 0 &&
                    <Select
                      showSearch
                      className="width-100-per"
                      placeholder="Chọn loại thưởng"
                      optionFilterProp="children"
                      onChange={handleSelectedTypeBonus}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        <Option value={0}>Vui lòng chọn</Option>
                  <Option value={1}>Thưởng doanh số</Option>
                  <Option value={2}>Thưởng dịch vụ</Option>
                  <Option value={3}>Thưởng thuê mặt bằng</Option>
                  <Option value={4}>Thưởng doanh số Signature</Option>
                  <Option value={5}>Thưởng dịch vụ Signature</Option>
                    </Select>
                  }
                </div>
              </Col>
            </div>
            <br />
            <div style={{height:100}}></div>
          </Modal>
    );
  }
}

JobtitlelevelModal.propTypes = propsTypes;

export default JobtitlelevelModal;