import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import '../App.css';
import RouteList from 'modules/route'
import Middlewware from './middleware'
import config from 'config'

require('dotenv').config()


class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Middlewware>
          <Switch>
            {RouteList.map((props, index) => <Route exact key={index} {...props} />)}
            <Redirect from='*' to={config.pathAdmin} />
          </Switch>
        </Middlewware>
      </BrowserRouter>
    );
  }
}


export default App;
