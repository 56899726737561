import BaseModel from 'model/base.js'

const apiUrl = 'api';

const EmployeePoint = {
  AddRaw: (query, data, sucCallback, errCallback, finCallback) => {
    let url = `${apiUrl}/employeepoint/addraw`;
    BaseModel.post(url,data, function (data) {
      sucCallback(data.data);
    },
      err => {
        errCallback(err)
      },
      () => finCallback())
  },
  getList: (query, sucCallback, errCallback, finCallback) => {
    let url = `${apiUrl}/employeepoint/getlist`;
    BaseModel.get(url,query, function (data) {
      sucCallback(data.data);
    },
      err => {
        errCallback(err)
      },
      () => finCallback())
  },
  deleteEmployeePoint: (id, sucCallback, errCallback, finCallback) => {
    let url = `${apiUrl}/employeepoint/delete`;
    BaseModel.delete(url,{_id:id}, function(res) {
      sucCallback(res);
    },
    err => errCallback(err),
    () => finCallback())
  }
}

export default EmployeePoint;