import adminMapping from 'modules/admin/mapping/route.js'
import adminLoading from 'modules/admin/loading/route.js'
import adminLogOut from 'modules/admin/log-out/route.js'
import adminSalary from 'modules/admin/salary-element/route.js'
import adminHome from 'modules/admin/home-page/route.js'
import AdminProcessBonus from 'modules/admin/process-bonus/route.js'
import AdminJobtitleLevel from 'modules/admin/jobtitlelevel/route.js'
import AdminBonusPoint from 'modules/admin/bonuspoint/route.js'
import AdminEmployeePoint from 'modules/admin/employeepoint/route.js'
import AdminDepartmentException from 'modules/admin/department_exception/route.js'
import AdminSettingSalaryElement from 'modules/admin/setting-salary-element/route.js'
import AdminBonusPointOther from 'modules/admin/bonuspointother/route.js'
const routes = [
    ...adminHome,
    ...adminLoading,
    ...adminLogOut,
    ...adminMapping,
    ...adminSalary,
    ...AdminProcessBonus,
    ...AdminJobtitleLevel,
    ...AdminBonusPoint,
    ...AdminEmployeePoint,
    ...AdminDepartmentException,
    ...AdminSettingSalaryElement,
    ...AdminBonusPointOther
];
export default routes;