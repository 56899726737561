import BaseModel from 'model/base.js';
const apiUrl = 'api';
const SalaryElementModel = {
  getList: (query, callback, callbackErr) => {
    let url = 'api/hara-work/salaryElements';
    BaseModel.get(url, query, function(data) {
      callback(data.data);
    }, function(error) {
      callbackErr(error);
    });
  },
  getListSettingSalaryElement: (query, callback, callbackErr,finCallback) => {
    let url = 'api/settingsalaryelement/getlist';
    BaseModel.get(url, query, function(data) {
      callback(data.data);
    }, function(error) {
      callbackErr(error);
    },() => finCallback());
  },
  chooseSalary: (idSalary, isChoose, callback, callbackErr) => {
    let url = 'api/hara-work/salaryElements/choose';
    if (isChoose) {
      BaseModel.post(url, idSalary, function(data) {
        callback(data.data);
      }, function(error) {
        callbackErr(error);
      });
    } else {
      BaseModel.delete(url, idSalary, function(data) {
        callback(data.status);
      }, function(error) {
        callbackErr(error);
      });
    }
  },
  getChooseSalary: (callback, callbackErr) => {
    let url = 'api/hara-work/salaryElements/choose';
    BaseModel.get(url, null, function(data) {
      callback(data.data);
    }, function(error) {
      callbackErr(error);
    });
  },
  postSettingSalaryElement: (data, sucCallback, errCallback, finCallback) => {
    let url = `${apiUrl}/settingsalaryelement/add`;
    BaseModel.post(
      url, 
      data, 
      res => {
        sucCallback(res)
      }, 
      err => {
        errCallback(err)
      },
      () => finCallback()
    )
  },
  putSettingSalaryElement: (data, sucCallback, errCallback, finCallback) => {
    let url = `${apiUrl}/settingsalaryelement/update`;
    BaseModel.put(url, data, function(res) {
      sucCallback(res);
    },
    err => errCallback(err),
    () => finCallback())
  },
  deleteSettingSalaryElement: (_id, sucCallback, errCallback, finCallback) => {
    let url = `${apiUrl}/settingsalaryelement/delete`;
    BaseModel.delete(url,{_id:_id}, function(res) {
      sucCallback(res);
    },
    err => errCallback(err),
    () => finCallback())
  }
}

export default SalaryElementModel;