import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actStoreList } from 'actions/admin/store';
import lodash from 'lodash';
import { actDepartmentList } from '../../../../actions/admin/department';
import { actAddMapping, actEditMapping, actDeleteMapping, actGetMapping } from '../../../../actions/admin/mapping';
import Select from 'antd/lib/select';
import PropTypes from 'prop-types';
import {Modal, Col, Tooltip } from 'antd';
// import { Pagination, Button } from 'antd';
import { Button } from 'antd';
import { Table, Divider } from 'antd';
import { Layout, Icon } from 'antd';
import AdminLayout from '../../admin-layout.js';
import AdminContentHeader from '../../admin-content-header.js';
import BonusModel from '../../../../model/bonus';
import BonusPointOtherModel from '../../../../model/bonuspointother';
import AlertBox from '../../../../component/alertbox/alertbox';
import LoadingIndex from '../../loading/container/index';
import { DatetimePicker, DatetimePickerTrigger } from 'rc-datetime-picker';
import moment from 'moment';
import 'rc-datetime-picker/dist/picker.css';
import SalaryElementModel from '../../../../model/salary-element';
import SalaryPeriodModel from '../../../../model/salaryperiod';
import DepartmentModel from '../../../../model/department';
import * as XLSX from 'xlsx';

const confirm = Modal.confirm;
const propTypes = {
  store: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      open_day: PropTypes.string,
      brand: PropTypes.string.isRequired
    })
  ).isRequired, // product nhan vao phai la array .isRequied la yeu cau phai co,
  department: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      parentId: PropTypes.number,
      status: PropTypes.number,
      parentName: PropTypes.string,
      description: PropTypes.string,
      departmentUnitId: PropTypes.number.isRequired,
      departmentUnitName: PropTypes.string.isRequired,
      managerJobtitleId: PropTypes.number,
      managerJobtitleName: PropTypes.string,
      address: PropTypes.string,
      jobtitles: PropTypes.arrayOf(PropTypes.string),
      paths: PropTypes.string,
      timeZoneId: PropTypes.number.isRequired,
      timeZone: PropTypes.string,
      establishedDate: PropTypes.string,
      orgId: PropTypes.number.isRequired,
      count: PropTypes.number.isRequired,
      isHr: PropTypes.bool,
      otherName: PropTypes.string
    })
  ),
  mapping: PropTypes.shape({
    addedMapping: PropTypes.shape({
      tcd_id: PropTypes.number,
      hr_department_id: PropTypes.number,
      created_at: PropTypes.string
    }),
    updatedMapping: PropTypes.shape({
      tcd_id: PropTypes.number,
      hr_department_id: PropTypes.number,
      created_at: PropTypes.string
    }),
    deletedMapping: PropTypes.shape({
      error: PropTypes.bool,
      message: PropTypes.arrayOf(PropTypes.string)
    })
  }),
  getStoreList: PropTypes.func.isRequired,
  getDepartmentList: PropTypes.func.isRequired,
  actAddMapping: PropTypes.func.isRequired,
  actEditMapping: PropTypes.func.isRequired,
  actDeleteMapping: PropTypes.func.isRequired,
  getMappingList: PropTypes.func.isRequired
}


const make_cols = refstr => {
  let o = [], C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i }
  return o;
};

//======================= Select ===================================//
const Option = Select.Option;
// function handleChange(value) {
//   console.log(`selected ${value}`);
// }
function handleBlur() {
  // console.log('blur');
}
function handleFocus() {
  // console.log('focus');
}
//===================== End Select ================================//

//===================== Pagination ================================//
// function showTotal(total) {
//   return `Hiện thị ${total} trong tổng số ${total}`;
// }
// function onShowSizeChange(current, pageSize) {
//   // console.log(current, pageSize);
// }
//==================== End Pagination =============================//

class BonusPointOther extends Component {
  //===================== Start modal =============================//
  state = {
    fromDateMoment: moment(),
    toDateMoment: moment(),
    departmentId: null,
    salaryElements: [],
    salaryperiods: [],
    selectedSalaryElement: null,
    selectedSalaryPeriod: null,
    fromDateServiceMoment: moment(),
    toDateServiceMoment: moment(),
    selectedSalaryElementService: null,
    selectedSalaryPeriodService: null,
    dataImport: [],
    bonuspointothers:[],
    departments:[],
    filterPeriodCode:0,
  }

  handleChange = (momentEvent, momentName) => {
    this.setState({
      [momentName]: momentEvent,
    })
  }
  calbonus = () => {
    BonusModel.processBonus(null, this.state.fromDateMoment.format('YYYY-MM-DD'), this.state.toDateMoment.format('YYYY-MM-DD'), this.state.selectedSalaryPeriod
      , this.state.selectedSalaryElement, rsp => {
        if (rsp.error) {

        }
        else {

        }
      }, (error) => {

      }, () => { })
  }

  postdata = () => {
    if(this.state.dataImport.length<=0){
      this.setState({
        alertVisitble: true,
        typeAlert: 'error',
        msgAlert: `Không có dữ liệu, vui lòng chọn file có dữ liệu`
      },()=>setTimeout(() => this.setState({
        alertVisitble: false,
      }), 3000));
      return;
    }
    var _this=this;
    _this.setState({
      alertVisitble: true,
      typeAlert: 'success',
      msgAlert: `Đang lưu dữ liệu vui lòng đợi`
    },()=>setTimeout(() => this.setState({
      alertVisitble: false,
    }), 3000));
    BonusPointOtherModel.AddRaw(null, this.state.dataImport, rsp => {
      this.getListBonusPointOther();
      document.getElementById("inputfile").value = "";
      _this.setState({
        dataImport:[],
        alertVisitble: true,
        typeAlert: 'success',
        msgAlert: `Thêm dữ liệu thành công`
      },()=>setTimeout(() => this.setState({
        alertVisitble: false,
      }), 3000));
    }, (error) => {
      this.setState({
        alertVisitble: true,
        typeAlert: 'error',
        msgAlert: `Thêm dữ liệu thất bại`
      },()=>setTimeout(() => this.setState({
        alertVisitble: false,
      }), 3000));
    }, () => { })
  }

  handleChangeSelectSalaryElement = id => {
    this.setState({
      selectedSalaryElement: id
    });
  }

  handleChangeSelectSalaryPeriod = id => {
    this.setState({
      selectedSalaryPeriod: id
    });
  }

    handleChangeSelectFilterSalaryPeriodCode = code => {
    this.setState({
      filterPeriodCode: code
    },()=>this.getListBonusPointOther());
    
  }

  handleChangeSelectSalaryElementService = id => {
    this.setState({
      selectedSalaryElementServicet: id
    });
  }

  handleChangeSelectSalaryPeriodService = id => {
    this.setState({
      selectedSalaryPeriodService: id
    });
  }

  componentDidMount() {
    this.getSalaryElement();
    this.getSalaryPeriod();
    this.getDepartments();
  }

  componentDidUpdate() {
  }
  getSalaryPeriod = () => {
    let _this = this;
    SalaryPeriodModel.getList(null, data => {
      _this.setState({ salaryperiods: data.items })
    },
      err => {
        this.setState({
          alertVisitble: true,
          typeAlert: 'error',
          msgAlert: `${err}`
        });
        //      this.getMapping(errStore, err);
      },
      () => { });
  }
  getSalaryElement = () => {
    let _this = this;
    SalaryElementModel.getList({ isgetall: true }, data => {
      _this.setState({ salaryElements: data.items })
    },
      err => {
        this.setState({
          alertVisitble: true,
          typeAlert: 'error',
          msgAlert: `${err}`
        });
        //      this.getMapping(errStore, err);
      },
      () => { });
  }
  getDepartments = errStore => {
    let _this = this;
    DepartmentModel.getList(data => {
      _this.setState({departments:data.items});
      this.getListBonusPointOther();
    },
    err => {
      _this.setState({
        alertVisitble: true,
        typeAlert: 'error',
        msgAlert: `${err}`
      });
    },
    () => {});
  };
  getListBonusPointOther = () => {
    let _this = this;
    BonusPointOtherModel.getList({salary_period_code : this.state.filterPeriodCode }, data => {
      var dataRe = data.items.map(a => {
        var department = _this.state.departments.filter(b => b.id == a.department_id)[0]
        a.departmentName = department ? department.name : '';
        return a;
      })
      _this.setState({ bonuspointothers: dataRe,isLoadingData:false });
    },
      err => {
        this.setState({
          alertVisitble: true,
          typeAlert: 'error',
          msgAlert: `${err}`
        });
        //      this.getMapping(errStore, err);
      },
      () => {setTimeout(() => this.setState({
        alertVisitble: false,
      }), 3000)});
  }

  deleteBonusPointOther = id => {
    let _this = this;
    BonusPointOtherModel.deleteBonusPointOther(id, data => {
      _this.setState({
        alertVisitble: true,
        msgAlert: 'Xóa điểm thưởng thành công',
        typeAlert: 'success'
      },()=>setTimeout(() => this.setState({
        alertVisitble: false,
      }), 3000))
    },
      err => {
        _this.setState({
          alertVisitble: true,
          msgAlert: `Xóa điểm thưởng thất bại. Chi tiết: ${err}`,
          typeAlert: 'error'
        },()=>setTimeout(() => this.setState({
          alertVisitble: false,
        }), 3000))
      },
      () => {
        _this.getListBonusPointOther();
      })
  }

  handleFile(fileUpload) {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    const _this = this;
    let file = fileUpload.target.files[0];
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', cellDates: true, cellNF: false, cellText: false });
      /* Get first worksheet */
      for (var i = 0; i < wb.SheetNames.length; i++) {
        const wsname = wb.SheetNames[i];
        const ws = wb.Sheets[wsname];
        let data = [];
        data = XLSX.utils.sheet_to_json(ws, { header: 1, dateNF: "DD-MM-YYYY" });
        let cols = make_cols(ws['!ref']);
        //    console.log('change', Utils.removeAccents(file.name.split(" ")))
        /* Update state */
        let dataPost = [];
        for (let j = 1; j < data.length; j++) {
          var object = { department_id: data[j][0], point: data[j][1],type_point:data[j][2], salary_period_code: data[j][3],brand:data[j][4] };
          dataPost.push(object);
        }
        _this.setState({ dataImport: dataPost })
      }
      /* Convert array of arrays */

    };
    if (rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
  };

  showConfirm=(_id)=> {
    var _this=this;
         confirm({
           content: "Bạn có thực sự muốn xóa dòng này ?",
           title:"Thông báo",
           onOk() {
             _this.deleteBonusPointOther(_id);
           },
           onCancel() {
             //console.log('Cancel');
           },
         });
   }

  columns() {
    let data = [{
      title: 'Đơn vị',
      dataIndex: 'departmentName',
      key: 'department_id',
      width:'40%'
    }, {
      title: 'Điểm số',
      dataIndex: 'point',
      key: 'point',
      width:'15%',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Loại điểm',
      dataIndex: 'type_point',
      key: 'type_point',
      width:'25%',
      render: text => <a>{text==1?"Điểm CR":"Điểm khác"}</a>,
    },
    // {
    //   title: 'Nhánh',
    //   dataIndex: 'brand',
    //   key: 'brand',
    //   width:'15%',
    //   render: text => <a>{text}</a>,
    // }
    // ,
    {
      title: '',
      key: 'action',
      width: '4%',
      render: (text, record) => (
        <span className="text-center">
          <Tooltip placement="left" title="Xoá">
            <span
              onClick={() => this.showConfirm(record._id)}
              style={{ color: '#1890ff', cursor: 'pointer' }}
            >
              <Icon type="delete" />
            </span>
          </Tooltip>
          {/* <Divider type="vertical" /> */}
          {/* <Tooltip placement="left" title="Chỉnh sửa">
            <span
              onClick={() => {
                this.showModal();
                this.changeIsEdittingStatus();
                this.handleChangeSelectJobtitle(record.jobtitle_id);
                this.handleChangeSelectBrand(record.brand);
                this.handlechangeInputLevel(record.level);
                this.handleSaveIdEditing(record._id);
              }}
              style={{ color: '#1890ff', cursor: 'pointer' }}
            >
              <Icon type="edit" theme="twoTone" />
            </span>
          </Tooltip> */}

        </span>
      ),
    }];
    return data;
  }
  render() {
    const { store, department } = this.props;
    return (

      <AdminLayout>
        <Layout>
          <div className="app-heading">
            <AdminContentHeader text="Điểm thưởng ngoài hệ thống" />
          </div>
          <div className="app-content">
            <Col className="gutter-row" span={6}>
              <input style={{cursor:"pointer"}} type='file' id="inputfile" className='' onChange={this.handleFile.bind(this)} />
            </Col>
            <Col className="gutter-row" span={6}>
            <div className="gutter-box">
                <Button type="primary" onClick={() => this.postdata()}><Icon type="save" theme="twoTone" />Lưu dữ liệu</Button>
              </div>
            </Col>
            <div className="clear"></div>
          </div>
          <div className="app-content">
          <Col className="gutter-row" span={6} style={{marginBottom:10}}>
                  <div className="gutter-box margin-right-15">
                    <Select
                      showSearch
                      className="width-100-per"
                      placeholder="Chu kỳ lương"
                      optionFilterProp="children"
                      onChange={this.handleChangeSelectFilterSalaryPeriodCode}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {this.state.salaryperiods && this.state.salaryperiods.map(s => 
                        <Option key={s.code} value={s.code}>{s.name}</Option>
                      )}
                    </Select>
                  </div>
                </Col>
              <Col  className="gutter-row" span={24}>
              <Table bordered size="middle" locale={{emptyText: 'Vui lòng chọn chu kỳ lương có dữ liệu.'}} columns={this.columns()} dataSource={this.state.bonuspointothers} pagination={false} />
              </Col>
            <div className="clear"></div>
          </div>
          
          {this.state.alertVisitble && 
                <AlertBox
                  message={this.state.msgAlert}
                  type={this.state.typeAlert}
                />
              }
        </Layout>
      </AdminLayout>
    );
  }
}

BonusPointOther.propTypes = propTypes;
const mapStateToProps = state => ({
  store: state.store,
  department: state.department,
  mapping: state.mapping
});
const mapDispatchToProps = dispatch => ({
  getStoreList: bindActionCreators(actStoreList, dispatch),
  getDepartmentList: bindActionCreators(actDepartmentList, dispatch),
  actAddMapping: bindActionCreators(actAddMapping, dispatch),
  actEditMapping: bindActionCreators(actEditMapping, dispatch),
  actDeleteMapping: bindActionCreators(actDeleteMapping, dispatch),
  getMappingList: bindActionCreators(actGetMapping, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(BonusPointOther);
