import {
  ACTION_JOBTITLE_LIST,
  ACTION_JOBTITLELEVEL_LIST,
  ACTION_JOBTITLELEVEL_EDIT,
  ACTION_JOBTITLELEVEL_ADD,
  ACTION_JOBTITLELEVEL_DELETE,
  ACTION_FILTER_JOBTITLELEVEL
} from '../../actions/admin/jobtitleLevel';

const initState = {
  addedJobtitleLevel: {},
  updatedJobtitleLevel: {},
  deletedJobtitleLevel: {},
  jobtitleLevelList: [],
  jobtite: []
};

const jobtitleLevel = (state = initState, action) => {
  switch (action.type) {
    case ACTION_JOBTITLE_LIST:
      state.jobtite = action.jobtitle.items;
      return state;
    case ACTION_JOBTITLELEVEL_LIST:
      state.jobtitleLevelList = action.jobtitleLevel.items;
      return state;
    case ACTION_JOBTITLELEVEL_ADD:
      state.addedJobtitleLevel = action.jobtitleLevel.data;
      return state;
    case ACTION_JOBTITLELEVEL_EDIT:
      state.updatedJobtitleLevel = action.jobtitleLevel;
      return state;
    case ACTION_JOBTITLELEVEL_DELETE:
      state.deletedJobtitleLevel = action.jobtitleLevel;
      return state;
    case ACTION_FILTER_JOBTITLELEVEL:
      state.jobtitleLevelList = action.jobtitleLevel;
      return state;
    default:
      return state;
  }
}

export default jobtitleLevel;