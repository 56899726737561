import {ACTION_LOAD_COMPONENT} from 'actions/admin/load-component.js'
import MappingIndex from 'modules/admin/mapping/container/index.js'
const initState = {
  component: MappingIndex
};

const loadcomponent = (state = initState, action) => {
  switch (action.type) {
    case ACTION_LOAD_COMPONENT:
      state = action.component;
      return state;
    default:
      return state
  }
}

export default loadcomponent;
