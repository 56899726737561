import Salary from 'modules/admin/setting-salary-element/container/index'
import config from 'config'
const routes = [
    {
      path: '/' + config.pathAdmin + 'setting_salary_element',
      component: Salary,
      option: {
        a: 'b'
      }
    }
];

export default routes;