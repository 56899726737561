import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actStoreList } from 'actions/admin/store';
import lodash from 'lodash';
import { actDepartmentList } from '../../../../actions/admin/department';
import { actAddMapping, actEditMapping, actDeleteMapping, actGetMapping } from '../../../../actions/admin/mapping';
import Select from 'antd/lib/select';
import PropTypes from 'prop-types';
import { Col, Tooltip,Row } from 'antd';
// import { Pagination, Button } from 'antd';
import { Button } from 'antd';
import { Table, Divider,Checkbox } from 'antd';
import { Layout, Icon } from 'antd';
import AdminLayout from '../../admin-layout.js';
import AdminContentHeader from '../../admin-content-header.js';
import BonusModel from '../../../../model/bonus';
import JobitleLevelModel from '../../../../model/jobitleLevelModel';
import SalaryPeriodModel from '../../../../model/salaryperiod';
import AlertBox from '../../../../component/alertbox/alertbox';
import LoadingIndex from '../../loading/container/index';
import { DatetimePicker, DatetimePickerTrigger } from 'rc-datetime-picker';
import moment from 'moment';
import 'rc-datetime-picker/dist/picker.css';
import SalaryElementModel from '../../../../model/setting-salary-element';

const propTypes = {
  store: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      open_day: PropTypes.string,
      brand: PropTypes.string.isRequired
    })
  ).isRequired, // product nhan vao phai la array .isRequied la yeu cau phai co,
  department: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      parentId: PropTypes.number,
      status: PropTypes.number,
      parentName: PropTypes.string,
      description: PropTypes.string,
      departmentUnitId: PropTypes.number.isRequired,
      departmentUnitName: PropTypes.string.isRequired,
      managerJobtitleId: PropTypes.number,
      managerJobtitleName: PropTypes.string,
      address: PropTypes.string,
      jobtitles: PropTypes.arrayOf(PropTypes.string),
      paths: PropTypes.string,
      timeZoneId: PropTypes.number.isRequired,
      timeZone: PropTypes.string,
      establishedDate: PropTypes.string,
      orgId: PropTypes.number.isRequired,
      count: PropTypes.number.isRequired,
      isHr: PropTypes.bool,
      otherName: PropTypes.string
    })
  ),
  mapping: PropTypes.shape({
    addedMapping: PropTypes.shape({
      tcd_id: PropTypes.number,
      hr_department_id: PropTypes.number,
      created_at: PropTypes.string
    }),
    updatedMapping: PropTypes.shape({
      tcd_id: PropTypes.number,
      hr_department_id: PropTypes.number,
      created_at: PropTypes.string
    }),
    deletedMapping: PropTypes.shape({
      error: PropTypes.bool,
      message: PropTypes.arrayOf(PropTypes.string)
    })
  }),
  getStoreList: PropTypes.func.isRequired,
  getDepartmentList: PropTypes.func.isRequired,
  actAddMapping: PropTypes.func.isRequired,
  actEditMapping: PropTypes.func.isRequired,
  actDeleteMapping: PropTypes.func.isRequired,
  getMappingList: PropTypes.func.isRequired
}


const RUN_Bonus_ProductTCH=1;
const RUN_Bonus_ServiceTCH=2;
const RUN_Bonus_RENT=3;
const RUN_Bonus_ProductTR=4;
const RUN_Bonus_ProductSIG=5;
const RUN_Bonus_ServiceSIG=6;

//======================= Select ===================================//
const Option = Select.Option;
// function handleChange(value) {
//   console.log(`selected ${value}`);
// }
function handleBlur() {
  // console.log('blur');
}
function handleFocus() {
  // console.log('focus');
}
//===================== End Select ================================//

//===================== Pagination ================================//
// function showTotal(total) {
//   return `Hiện thị ${total} trong tổng số ${total}`;
// }
// function onShowSizeChange(current, pageSize) {
//   // console.log(current, pageSize);
// }
//==================== End Pagination =============================//

class ProcessBonus extends Component {
  //===================== Start modal =============================//
  state = {
    fromDateMoment: moment(),
    toDateMoment: moment(),
    departmentId: null,
    salaryElements:[],
    salaryperiods:[],
    selectedSalaryElement:null,
    selectedSalaryPeriod:null,
    fromDateServiceMoment:moment(),
    toDateServiceMoment:moment(),
    selectedSalaryElementService:null,
    selectedSalaryPeriodService:null,
    isRunsalary:false,
    elementSaleTCH:null,
    elementServiceTCH:null,
    elementTMB:null,
    elementSaleTR:null,
    elementSaleSig:null,
    elementServiceSig:null,
    settingSalaryElement:[],
  }

  handleChange = (momentEvent, momentName) => {
    this.setState({
      [momentName]: momentEvent,
    })
  }
  calbonus = () => {
    if(!this.state.fromDateMoment){
      this.setState({
        alertVisitble: true,
        msgAlert: `Vui lòng chọn ngày bắt đầu`,
        typeAlert: 'error'
      },()=>setTimeout(() => this.setState({
        alertVisitble: false,
      }), 3000));
      return;
    }
    if(!this.state.toDateMoment){
      this.setState({
        alertVisitble: true,
        msgAlert: `Vui lòng chọn ngày kết thúc`,
        typeAlert: 'error'
      },()=>setTimeout(() => this.setState({
        alertVisitble: false,
      }), 3000));
      return;
    }
      if(this.state.fromDateMoment.toDate().getTime()>this.state.toDateMoment.toDate().getTime()){
        this.setState({
          alertVisitble: true,
          msgAlert: `Thời gian không hợp lệ, vui lòng chọn từ ngày bé hơn hoặc bằng đến ngày`,
          typeAlert: 'error'
        },()=>setTimeout(() => this.setState({
          alertVisitble: false,
        }), 3000));
        return;
      }
      if(this.state.selectedSalaryPeriod==null) {
        this.setState({
          alertVisitble: true,
          msgAlert: `Vui lòng chọn chu kỳ lương`,
          typeAlert: 'error'
        },()=>setTimeout(() => this.setState({
          alertVisitble: false,
        }), 3000));
        return;
      }
      if(this.state.elementSaleTCH==null&&this.state.elementTMB==null&&this.state.elementServiceTCH==null&&this.state.elementSaleSig==null&&this.state.elementSaleTR==null&&this.state.elementServiceSig==null)
      {
        this.setState({
          alertVisitble: true,
          msgAlert: `Vui lòng chọn ít nhất một loại thưởng để tính thưởng.`,
          typeAlert: 'error'
        },()=>setTimeout(() => this.setState({
          alertVisitble: false,
        }), 3000));
        return;
      }
    this.setState({isRunsalary:true})
    BonusModel.processBonus(null, this.state.fromDateMoment.format('YYYY-MM-DD'), this.state.toDateMoment.format('YYYY-MM-DD'),this.state.selectedSalaryPeriod
    ,this.state.elementSaleTCH,this.state.elementServiceTCH,this.state.elementTMB,this.state.elementSaleTR,this.state.elementSaleSig,this.state.elementServiceSig, rsp => {
      this.checkRunSalary();
      this.setState({
        alertVisitble: true,
        msgAlert: `Tính thưởng thành công`,
        typeAlert: 'success'
      },()=>setTimeout(() => this.setState({
        alertVisitble: false,
      }), 3000))
    }, (error) => {
      this.checkRunSalary();
      this.setState({
        isRunsalary:false,
        alertVisitble: true,
        msgAlert: `Chi tiết: ${error}`,
        typeAlert: 'error'
      },()=>setTimeout(() => this.setState({
        alertVisitble: false,
      }), 3000))
    }, () => { })
  }

  calbonusService = (isServiceBonus) => {
    if(isServiceBonus){
      if(this.state.fromDateServiceMoment.toDate().getTime()>this.state.toDateServiceMoment.toDate().getTime()){
        this.setState({
          alertVisitble: true,
          msgAlert: `Thời gian không hợp lệ, vui lòng chọn từ ngày bé hơn hoặc bằng đến ngày`,
          typeAlert: 'error'
        },()=>setTimeout(() => this.setState({
          alertVisitble: false,
        }), 3000));
        return;
      }
      if(this.state.selectedSalaryPeriodService==null) {
        this.setState({
          alertVisitble: true,
          msgAlert: `Vui lòng chọn chu kỳ lương tính thưởng dịch vụ`,
          typeAlert: 'error'
        },()=>setTimeout(() => this.setState({
          alertVisitble: false,
        }), 3000));
        return;
      }
      if(this.state.selectedSalaryElementService==null){
        this.setState({
          alertVisitble: true,
          msgAlert: `Vui lòng chọn thành phần lương tính thưởng dịch vụ`,
          typeAlert: 'error'
        },()=>setTimeout(() => this.setState({
          alertVisitble: false,
        }), 3000));
        return;
      }
      
    }
    else{
      if(this.state.fromDateMoment.toDate().getTime()>this.state.toDateMoment.toDate().getTime()){
        this.setState({
          alertVisitble: true,
          msgAlert: `Thời gian không hợp lệ, vui lòng chọn từ ngày bé hơn hoặc bằng đến ngày`,
          typeAlert: 'error'
        },()=>setTimeout(() => this.setState({
          alertVisitble: false,
        }), 3000));
        return;
      }
      if(this.state.selectedSalaryPeriod==null) {
        this.setState({
          alertVisitble: true,
          msgAlert: `Vui lòng chọn chu kỳ lương tính thưởng doanh số`,
          typeAlert: 'error'
        },()=>setTimeout(() => this.setState({
          alertVisitble: false,
        }), 3000));
        return;
      }
      if(this.state.selectedSalaryElement==null){
        this.setState({
          alertVisitble: true,
          msgAlert: `Vui lòng chọn thành phần lương tính thưởng doanh số`,
          typeAlert: 'error'
        },()=>setTimeout(() => this.setState({
          alertVisitble: false,
        }), 3000));
        return;
      }
      
    }
    this.setState({isRunsalary:true})
    BonusModel.processBonus(null, this.state.fromDateMoment.format('YYYY-MM-DD'), this.state.toDateMoment.format('YYYY-MM-DD'),this.state.selectedSalaryPeriodService
    ,this.state.selectedSalaryElementService,isServiceBonus, rsp => {
      this.checkRunSalary();
      this.setState({
        alertVisitble: true,
        msgAlert: `Tính thưởng thành công`,
        typeAlert: 'success'
      },()=>setTimeout(() => this.setState({
        alertVisitble: false,
      }), 3000))
    }, (error) => {
      this.checkRunSalary();
      this.setState({
        isRunsalary:false,
        alertVisitble: true,
        msgAlert: `Chi tiết: ${error}`,
        typeAlert: 'error'
      },()=>setTimeout(() => this.setState({
        alertVisitble: false,
      }), 3000))
    }, () => { })
  }

  handleChangeSelectSalaryElement= (id,name) => {
    this.setState({
      [name]: id
    });
  }

  handleChangeSelectSalaryPeriod= id => {
    this.setState({
      selectedSalaryPeriod: id
    });
  } 


  checkRunSalary=()=>{
    BonusModel.checkRunSalary(null, null, rsp => {
      if(rsp.error){
        this.setState({isRunsalary:true})
        setTimeout(() => {
          this.checkRunSalary();
        }, 5000);
      }
      else{
        this.setState({isRunsalary:false})
      }
    }, (error) => {
      this.setState({
        alertVisitble: true,
        msgAlert: `Chi tiết: ${error}`,
        typeAlert: 'error'
      },()=>setTimeout(() => this.setState({
        alertVisitble: false,
      }), 3000))
    }, () => { })
  }

  handleChangeSelectSalaryElementService = id => {
    this.setState({
      selectedSalaryElementService: id
    });
  }

  handleChangeSelectSalaryPeriodService = id => {
    this.setState({
      selectedSalaryPeriodService: id
    });
  }

  componentDidMount() {
    this.getSalaryElement();
    this.getSalaryPeriod();
    this.checkRunSalary();
    this.getSettingSalaryElement();
  } 

  componentDidUpdate() {
  }
  getSalaryPeriod = () => {
    let _this=this;
    SalaryPeriodModel.getList(null,data => {
      _this.setState({salaryperiods:data.items})
    },
      err => {
        this.setState({
          alertVisitble: true,
          typeAlert: 'error',
          msgAlert: `${err}`
        });
        //      this.getMapping(errStore, err);
      },
      () => { });
  }
  getSalaryElement = () => {
    let _this=this;
    SalaryElementModel.getList({isgetall:true},data => {
      _this.setState({salaryElements:data.items})
    },
      err => {
        this.setState({
          alertVisitble: true,
          typeAlert: 'error',
          msgAlert: `${err}`
        });
        //      this.getMapping(errStore, err);
      },
      () => { });
  }
  getSettingSalaryElement = () => {
    let _this=this;
    SalaryElementModel.getListSettingSalaryElement({},data => {
      _this.setState({settingSalaryElement:data.items})
    },
      err => {
        this.setState({
          alertVisitble: true,
          typeAlert: 'error',
          msgAlert: `${err}`
        });
        //      this.getMapping(errStore, err);
      },
      () => { });
  }
  onChange=(e)=>{
    var settingSalaryElement=this.state.settingSalaryElement;
    if(e.length==0){
      this.setState({
        elementSaleTCH:null,
        elementServiceTCH:null,
        elementTMB:null,
        elementSaleTR:null,
        elementServiceSig:null,
        elementSaleSig:null,
      })
    }
      if(e.indexOf(RUN_Bonus_ProductTCH)>-1){
        var salaryElementMap=settingSalaryElement.filter(a=>a.type_point==RUN_Bonus_ProductTCH)[0];
        if(salaryElementMap){
          this.setState({elementSaleTCH:salaryElementMap.salaryelement_id})
        }
      }
      else{
        this.setState({elementSaleTCH:null})
      }
      if(e.indexOf(RUN_Bonus_ServiceTCH)>-1){
        var salaryElementMap=settingSalaryElement.filter(a=>a.type_point==RUN_Bonus_ServiceTCH)[0];
        if(salaryElementMap){
          this.setState({elementServiceTCH:salaryElementMap.salaryelement_id})
        }
      }
      else{
        this.setState({elementServiceTCH:null})
      }
      if(e.indexOf(RUN_Bonus_RENT)>-1){
        var salaryElementMap=settingSalaryElement.filter(a=>a.type_point==RUN_Bonus_RENT)[0];
        if(salaryElementMap){
          this.setState({elementTMB:salaryElementMap.salaryelement_id})
        }
        
      }else{
        this.setState({elementTMB:null})
      }
      if(e.indexOf(RUN_Bonus_ProductTR)>-1){
        var salaryElementMap=settingSalaryElement.filter(a=>a.type_point==RUN_Bonus_ProductTR)[0];
        if(salaryElementMap){
          this.setState({elementSaleTR:salaryElementMap.salaryelement_id})
        }
        
      }else{
        this.setState({elementSaleTR:null})
      }
      if(e.indexOf(RUN_Bonus_ProductSIG)>-1){
        var salaryElementMap=settingSalaryElement.filter(a=>a.type_point==RUN_Bonus_ProductSIG)[0];
        if(salaryElementMap){
          this.setState({elementSaleSig:salaryElementMap.salaryelement_id})
        }
        
      }else{
        this.setState({elementSaleSig:null})
      }
      if(e.indexOf(RUN_Bonus_ServiceSIG)>-1){
        var salaryElementMap=settingSalaryElement.filter(a=>a.type_point==RUN_Bonus_ServiceSIG)[0];
        if(salaryElementMap){
          this.setState({elementServiceSig:salaryElementMap.salaryelement_id})
        }
      }
      else{
        this.setState({elementServiceSig:null})
      }
  }
  render() {
    const { store, department } = this.props;
    return (

      <AdminLayout>
        <Layout>
          <div className="app-heading">
            <AdminContentHeader text="Tính thưởng" />
              <div className="gutter-box" style={{float: 'right'}}>
                <Button type="primary" disabled={this.state.isRunsalary} onClick={() => this.calbonus(false)}><Icon type="tool" theme="twoTone" />{this.state.isRunsalary? "Đang Tính thưởng...":"Tính thưởng"}</Button>
              </div>
          </div>
          <div className="app-content">
          <Row gutter={5}>
          <Col className="gutter-row" span={6}>
              <div className="gutter-box margin-right-15">
              <label className="text-title-field col-md-12">
                  Chu kỳ lương <span className="text-danger">*</span>
                </label>
                <Select
                      showSearch
                      className="width-100-per"
                      placeholder="Vui lòng chọn"
                      optionFilterProp="children"
                      onChange={this.handleChangeSelectSalaryPeriod}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {this.state.salaryperiods && this.state.salaryperiods.map(d =>
                        <Option key={d.code} value={d.code}>{d.name}</Option>
                      )}
                    </Select>
              </div>
            </Col>

            <Col className="gutter-row" span={4}>
              <div className="gutter-box margin-right-15">
                <label className="text-title-field col-md-12">
                  Từ ngày <span className="text-danger">*</span>
                </label>
                <DatetimePickerTrigger
                  showTimePicker={false}
                  moment={this.state.fromDateMoment}
                  onChange={e => this.handleChange(e, 'fromDateMoment')}>
                  <input type="text" style={{padding:10}} className="ant-select-selection
            ant-select-selection--single" value={this.state.fromDateMoment.format('DD/MM/YYYY')} />
                </DatetimePickerTrigger>
              </div>
            </Col>

            <Col className="gutter-row" span={4}>
              <div className="gutter-box margin-right-15">
                <label className="text-title-field col-md-12">
                  Đến ngày <span className="text-danger">*</span>
                </label>
                <DatetimePickerTrigger
                  showTimePicker={false}
                  moment={this.state.toDateMoment}
                  onChange={e => this.handleChange(e, 'toDateMoment')}>
                  <input type="text" style={{padding:10}} className="ant-select-selection
            ant-select-selection--single" value={this.state.toDateMoment.format('DD/MM/YYYY')} />
                </DatetimePickerTrigger>
              </div>
            </Col>
            </Row>
            </div>
          <div className="app-content">
            <Row gutter={5}>
            <Checkbox.Group options={[
              { label: 'Thưởng doanh số TCH', value: 1 },
              { label: 'Thưởng dịch vụ TCH', value: 2 },
              { label: 'Thưởng doanh số thuê mặt bằng', value: 3 },
              { label: 'Thưởng doanh số Tenren', value: 4 },
              { label: 'Thưởng doanh số Signature', value: 5 },
              { label: 'Thưởng dịch vụ Signature', value: 6 },
              ]} onChange={(e)=>this.onChange(e)} />
            {/* <Col className="gutter-row" span={6}>
              <div className="gutter-box margin-right-15">
              <label className="text-title-field col-md-12">
                  Thành phần thưởng doanh số TCH 
                </label>
                <Select
                      showSearch
                      className="width-100-per"
                      placeholder="Vui lòng chọn"
                      optionFilterProp="children"
                      onChange={(e)=>this.handleChangeSelectSalaryElement(e,'elementSaleTCH')}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      filterOption={(input, option) => {
                        let optionData = option.props.children;
                        if (typeof option !== 'string') {
                          optionData = optionData.join('');
                        }
                        return optionData.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }}
                    >
                      {this.state.salaryElements && this.state.salaryElements.map(d =>
                        <Option key={d.id} value={d.id}>{d.name} </Option>
                      )}
                    </Select>
                    <input type="checkbox" ></input>
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div className="gutter-box margin-right-15">
              <label className="text-title-field col-md-12">
                  Thành phần thưởng dịch vụ TCH 
                </label>
                <Select
                      showSearch
                      className="width-100-per"
                      placeholder="Vui lòng chọn"
                      optionFilterProp="children"
                      onChange={(e)=>this.handleChangeSelectSalaryElement(e,'elementServiceTCH')}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      filterOption={(input, option) => {
                        let optionData = option.props.children;
                        if (typeof option !== 'string') {
                          optionData = optionData.join('');
                        }
                        return optionData.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }}
                    >
                      {this.state.salaryElements && this.state.salaryElements.map(d =>
                        <Option key={d.id} value={d.id}>{d.name} </Option>
                      )}
                    </Select>
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div className="gutter-box margin-right-15">
              <label className="text-title-field col-md-12">
                  Thành phần thưởng doanh số thuê mặt bằng 
                </label>
                <Select
                      showSearch
                      className="width-100-per"
                      placeholder="Vui lòng chọn"
                      optionFilterProp="children"
                      onChange={(e)=>this.handleChangeSelectSalaryElement(e,'elementTMB')}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      filterOption={(input, option) => {
                        let optionData = option.props.children;
                        if (typeof option !== 'string') {
                          optionData = optionData.join('');
                        }
                        return optionData.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }}
                    >
                      {this.state.salaryElements && this.state.salaryElements.map(d =>
                        <Option key={d.id} value={d.id}>{d.name} </Option>
                      )}
                    </Select>
              </div>
            </Col> */}

            </Row>
            <div className="clear"></div>
          </div>
          {/* <div className="app-content">
          <Row gutter={5}>
          <Col className="gutter-row" span={6}>
              <div className="gutter-box margin-right-15">
              <label className="text-title-field col-md-12">
                  Thành phần thưởng doanh số Tenren
                </label>
                <Select
                      showSearch
                      className="width-100-per"
                      placeholder="Vui lòng chọn"
                      optionFilterProp="children"
                      onChange={(e)=>this.handleChangeSelectSalaryElement(e,'elementSaleTR')}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      filterOption={(input, option) => {
                        let optionData = option.props.children;
                        if (typeof option !== 'string') {
                          optionData = optionData.join('');
                        }
                        return optionData.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }}
                    >
                      {this.state.salaryElements && this.state.salaryElements.map(d =>
                        <Option key={d.id} value={d.id}>{d.name} </Option>
                      )}
                    </Select>
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div className="gutter-box margin-right-15">
              <label className="text-title-field col-md-12">
                  Thành phần thưởng doanh số Signature 
                </label>
                <Select
                      showSearch
                      className="width-100-per"
                      placeholder="Vui lòng chọn"
                      optionFilterProp="children"
                      onChange={(e)=>this.handleChangeSelectSalaryElement(e,'elementSaleSig')}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      filterOption={(input, option) => {
                        let optionData = option.props.children;
                        if (typeof option !== 'string') {
                          optionData = optionData.join('');
                        }
                        return optionData.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }}
                    >
                      {this.state.salaryElements && this.state.salaryElements.map(d =>
                        <Option key={d.id} value={d.id}>{d.name} </Option>
                      )}
                    </Select>
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div className="gutter-box margin-right-15">
              <label className="text-title-field col-md-12">
                  Thành phần thưởng dịch vụ Signature 
                </label>
                <Select
                      showSearch
                      className="width-100-per"
                      placeholder="Vui lòng chọn"
                      optionFilterProp="children"
                      onChange={(e)=>this.handleChangeSelectSalaryElement(e,'elementServiceSig')}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      filterOption={(input, option) => {
                        let optionData = option.props.children;
                        if (typeof option !== 'string') {
                          optionData = optionData.join('');
                        }
                        return optionData.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }}
                    >
                      {this.state.salaryElements && this.state.salaryElements.map(d =>
                        <Option key={d.id} value={d.id}>{d.name} </Option>
                      )}
                    </Select>
              </div>
            </Col>
            </Row>
            <div className="clear"></div>
          </div> */}
          {this.state.alertVisitble && 
                <AlertBox
                  message={this.state.msgAlert}
                  type={this.state.typeAlert}
                />
              }
        </Layout>
      </AdminLayout>
    );
  }
}

ProcessBonus.propTypes = propTypes;
const mapStateToProps = state => ({
  store: state.store,
  department: state.department,
  mapping: state.mapping
});
const mapDispatchToProps = dispatch => ({
  getStoreList: bindActionCreators(actStoreList, dispatch),
  getDepartmentList: bindActionCreators(actDepartmentList, dispatch),
  actAddMapping: bindActionCreators(actAddMapping, dispatch),
  actEditMapping: bindActionCreators(actEditMapping, dispatch),
  actDeleteMapping: bindActionCreators(actDeleteMapping, dispatch),
  getMappingList: bindActionCreators(actGetMapping, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ProcessBonus);
