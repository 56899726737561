import mapping from 'modules/admin/mapping/container/index'
// import MappingDetail from 'modules/admin/mapping/container/detail'
import config from 'config'
const routes = [
    // {
    //   path: '/' + config.pathAdmin + 'mapping/:id',
    //   component: MappingDetail,
    //   state: {
    //     a: 'b'
    //   }
    // },
    {
      path: '/' + config.pathAdmin + 'mapping',
      component: mapping,
      option: {
        a: 'b'
      }
    }
];

export default routes;