export const ACTION_JOBTITLE_LIST = 'ACTION_JOBTITLE_LIST';
export const ACTION_JOBTITLELEVEL_LIST = 'ACTION_JOBTITLELEVEL_LIST';
export const ACTION_JOBTITLELEVEL_ADD = 'ACTION_JOBTITLELEVEL_ADD';
export const ACTION_JOBTITLELEVEL_EDIT = 'ACTION_JOBTITLELEVEL_EDIT';
export const ACTION_JOBTITLELEVEL_DELETE = 'ACTION_JOBTITLELEVEL_DELETE';
export const ACTION_FILTER_JOBTITLELEVEL = 'ACTION_FILTER_JOBTITLELEVEL';

export const actListJobtitle = jobtitle => {
  return {
    type: ACTION_JOBTITLE_LIST,
    jobtitle
  }
};

export const actListJobtitleLevel = jobtitle => {
  return {
    type: ACTION_JOBTITLELEVEL_LIST,
    jobtitle
  }
};

export const actAddJobtitleLevel = mapping => {
  return {
    type: ACTION_JOBTITLELEVEL_ADD,
    mapping
  }
}

export const actEditJobtitleLevel = mapping => {
  return {
    type: ACTION_JOBTITLELEVEL_EDIT,
    mapping
  }
}

export const actDeleteJobtitleLevel = mapping => {
  return {
    type: ACTION_JOBTITLELEVEL_DELETE,
    mapping
  }
}

export const actFilterJobtitleLevel = mapping => ({
  type: ACTION_FILTER_JOBTITLELEVEL,
  mapping
})