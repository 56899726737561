import { combineReducers } from 'redux'
import store from 'reducers/admin/store'
import loadcomponent from 'reducers/admin/load-component.js'
import department from './admin/department';
import mapping from './admin/mapping';
import jobtitleLevel from './admin/jobtitleLevel';
import selectedAdminMenuItem from './admin/admin-menu-left';

const rootReducer = combineReducers({
  department,
  store,
  mapping,
  selectedAdminMenuItem,
  loadcomponent,
  jobtitleLevel,
});

export default rootReducer
