import BaseModel from 'model/base.js';
const SalaryElementModel = {
  getList: (query, callback, callbackErr) => {
    let url = 'api/hara-work/salaryElements';
    BaseModel.get(url, query, function(data) {
      callback(data.data);
    }, function(error) {
      callbackErr(error);
    });
  },
  chooseSalary: (idSalary, isChoose, callback, callbackErr) => {
    let url = 'api/hara-work/salaryElements/choose';
    if (isChoose) {
      BaseModel.post(url, idSalary, function(data) {
        callback(data.data);
      }, function(error) {
        callbackErr(error);
      });
    } else {
      BaseModel.delete(url, idSalary, function(data) {
        callback(data.status);
      }, function(error) {
        callbackErr(error);
      });
    }
  },
  getChooseSalary: (callback, callbackErr) => {
    let url = 'api/hara-work/salaryElements/choose';
    BaseModel.get(url, null, function(data) {
      callback(data.data);
    }, function(error) {
      callbackErr(error);
    });
  }
}
export default SalaryElementModel;