import Home from 'modules/admin/home-page/container/index'
import config from 'config'
const routes = [
    {
      path: '/' + config.pathAdmin,
      component: Home,
      option: {
        a: 'b'
      }
    }
];

export default routes;