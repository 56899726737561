export const ACTION_MAPPING_LIST = 'ACTION_MAPPING_LIST';
export const ACTION_MAPPING_ADD = 'ACTION_MAPPING_ADD';
export const ACTION_MAPPING_EDIT = 'ACTION_MAPPING_EDIT';
export const ACTION_MAPPING_DELETE = 'ACTION_MAPPING_DELETE';
export const ACTION_FILTER_MAPPING = 'ACTION_FILTER_MAPPING';

export const actGetMapping = mapping => {
  return {
    type: ACTION_MAPPING_LIST,
    mapping
  }
}

export const actAddMapping = mapping => {
  return {
    type: ACTION_MAPPING_ADD,
    mapping
  }
}

export const actEditMapping = mapping => {
  return {
    type: ACTION_MAPPING_EDIT,
    mapping
  }
}

export const actDeleteMapping = mapping => {
  return {
    type: ACTION_MAPPING_DELETE,
    mapping
  }
}

export const actFilterMapping = mapping => ({
  type: ACTION_FILTER_MAPPING,
  mapping
})