import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actStoreList } from 'actions/admin/store';
import lodash from 'lodash';
import { actDepartmentList } from '../../../../actions/admin/department';
import { actAddMapping, actEditMapping, actDeleteMapping, actGetMapping } from '../../../../actions/admin/mapping';
import Select from 'antd/lib/select';
import PropTypes from 'prop-types';
import { Col, Tooltip } from 'antd';
// import { Pagination, Button } from 'antd';
import { Button } from 'antd';
import {Modal, Table, Divider } from 'antd';
import { Layout, Icon } from 'antd';
import AdminLayout from '../../admin-layout.js';
import AdminContentHeader from '../../admin-content-header.js';
import MappingModal from '../mappingModal/mappingModal';
import StoreModel from '../../../../model/store';
import DepartmentModel from '../../../../model/department';
import MappingModel from '../../../../model/mapping';
import AlertBox from '../../../../component/alertbox/alertbox';
import LoadingIndex from '../../loading/container/index';

const confirm = Modal.confirm;
const propTypes = {
  store: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      open_day: PropTypes.string,
      brand: PropTypes.string.isRequired
    })
  ).isRequired, // product nhan vao phai la array .isRequied la yeu cau phai co,
  department: PropTypes.arrayOf(
    PropTypes.shape({
      id                  : PropTypes.number.isRequired,
      code                : PropTypes.string.isRequired,
      name                : PropTypes.string.isRequired,
      parentId            : PropTypes.number,
      status              : PropTypes.number,
      parentName          : PropTypes.string,
      description         : PropTypes.string,
      departmentUnitId    : PropTypes.number.isRequired,
      departmentUnitName  : PropTypes.string.isRequired,
      managerJobtitleId   : PropTypes.number,
      managerJobtitleName : PropTypes.string,
      address             : PropTypes.string,
      jobtitles           : PropTypes.arrayOf(PropTypes.string),
      paths               : PropTypes.string,
      timeZoneId          : PropTypes.number.isRequired,
      timeZone            : PropTypes.string,
      establishedDate     : PropTypes.string,
      orgId               : PropTypes.number.isRequired,
      count               : PropTypes.number.isRequired,
      isHr                : PropTypes.bool,
      otherName           : PropTypes.string
    })
  ),
  mapping: PropTypes.shape({
    addedMapping: PropTypes.shape({
      tcd_id: PropTypes.number,
      hr_department_id: PropTypes.number,
      created_at: PropTypes.string
    }),
    updatedMapping: PropTypes.shape({
      tcd_id: PropTypes.number,
      hr_department_id: PropTypes.number,
      created_at: PropTypes.string
    }),
    deletedMapping: PropTypes.shape({
      error: PropTypes.bool,
      message: PropTypes.arrayOf(PropTypes.string)
    })
  }),
  getStoreList: PropTypes.func.isRequired,
  getDepartmentList: PropTypes.func.isRequired,
  actAddMapping: PropTypes.func.isRequired,
  actEditMapping: PropTypes.func.isRequired,
  actDeleteMapping: PropTypes.func.isRequired,
  getMappingList: PropTypes.func.isRequired
}




//======================= Select ===================================//
const Option = Select.Option;
// function handleChange(value) {
//   console.log(`selected ${value}`);
// }
function handleBlur() {
  // console.log('blur');
}
function handleFocus() {
  // console.log('focus');
}
//===================== End Select ================================//

//===================== Pagination ================================//
// function showTotal(total) {
//   return `Hiện thị ${total} trong tổng số ${total}`;
// }
// function onShowSizeChange(current, pageSize) {
//   // console.log(current, pageSize);
// }
//==================== End Pagination =============================//

class MappingIndexs extends Component {
  //===================== Start modal =============================//
  state = {
    ModalText: 'Content of the modal',
    visible: false,
    confirmLoading: false,
    isEditting: false,
    selectedStore: 0,
    selectedDepartment: 0,
    selectedStoreEditting:null,
    selectedDepartmentEditting:null,
    filterStore: 0,
    filterDepartment: 0,
    filterData: [],
    alertVisitble: false,
    typeAlert: 'success',
    msgAlert: 'Success',
    queryMapping: '',
    isLoadingData: true,
    idEditing:null,
  }

  handleChangeSelectStore = id => {
    this.setState({
      selectedStore: id
    });
  }

  handleChangeSelectDepartment = id => {
    this.setState({
      selectedDepartment: id
    })
  }

  handleChangeFilterStore = id => {
    this.setState({
      filterStore: id
    })
  }

  handleChangeFilterDepartment = id => {
    this.setState({
      filterDepartment: id
    })
  }

  showModal = e => {
    if (e) {
      this.setState({
        isEditting: false
      })
    }
    this.setState({
      visible: true,
    });
  }

  changeIsEdittingStatus = (_id,store_id,department_id) => {
    this.setState({
      isEditting: true,
      selectedDepartmentEditting:department_id,
      selectedStoreEditting:store_id,
      idEditing:_id,
    })
  }

//====================== Start Table ==============================//

showConfirm=(_id)=> {
  var _this=this;
       confirm({
         content: "Bạn có thực sự muốn xóa dòng này ?",
         title:"Thông báo",
         onOk() {
           _this.deleteMapping(_id);
         },
         onCancel() {
           //console.log('Cancel');
         },
       });
 }

columns() {
  let data = [{
    title: 'Cửa hàng',
    dataIndex: 'storeName',
    key: 'store_id',
    width:'35%',
  }, {
    width:'35%',
    title: 'Đơn vị',
    dataIndex: 'departmentName',
    key: 'hr_department_id',
    render: text => <a>{text}</a>,
  },
  {
    width:'20%',
    title: 'Nhánh',
    dataIndex: 'brand',
    key: 'brand',
    render: text => <a>{text}</a>,
  }, {
    title: '',
    key: 'action',
    width: '7%',
    render: (text, record) => (
      <span className="text-center">
        <Tooltip placement="left" title="Xoá">
          <span
            onClick={() => this.showConfirm(record._id)}
            style={{color: '#1890ff', cursor: 'pointer'}}
          >
            <Icon type="delete" /> 
          </span>
        </Tooltip>
        <Divider type="vertical" />
        <Tooltip placement="left" title="Chỉnh sửa">
          <span 
            onClick={() => {
              this.showModal(); 
              this.changeIsEdittingStatus(record._id,record.tch_id,record.hr_department_id);
              this.handleChangeSelectStore(record.tch_id + "#" + record.brand);
              this.handleChangeSelectDepartment(record.hr_department_id);
            }} 
            style={{color: '#1890ff', cursor: 'pointer'}}
          > 
            <Icon type="edit" theme="twoTone" />
          </span>
        </Tooltip>

      </span>
    ),
  }];
  return data;
}
filterData = () => {
  const {mapping, store, department} = this.props;

  let filteredMapping = mapping.mappingList ? mapping.mappingList : [];

  const data = filteredMapping.map(m => {
    let storeMap = lodash.find(store, {'id': m.store_id, 'brand': m.brand});
    let depart =lodash.find(department, ['id', m.hr_department_id]);
    let data = {
      key: `${m._id}${m.hr_department_id}`,
      tch_id: m.store_id,
      brand: m.brand,
      _id: m._id,
      hr_department_id: m.hr_department_id,
      storeName: lodash.get(storeMap, 'name', '') + ' - [' + lodash.get(storeMap, 'brand', '') + ']',
      departmentName: lodash.get(depart, 'name', '')  + ' - [' + (lodash.get(depart, 'parentName', '')==null?'':lodash.get(depart, 'parentName', '')) + ']',
    };

    return data;
  });

  this.setState({
    filterData: data
  })
}

//==================================== End Table ==============================//
  
  handleOk = () => {
    const {selectedStore, selectedDepartment} = this.state;
    // if(selectedStore==null||selectedStore==0){
    //   this.setState({
    //     visible:true,
    //     alertVisitble: true,
    //     msgAlert: 'Vui lòng chọn cửa hàng.',
    //     typeAlert: 'error'
    //   },()=>setTimeout(() => this.setState({
    //     alertVisitble: false,
    //   }), 3000));
    //   return;
    // }
    if(selectedDepartment==null||selectedDepartment==0){
      this.setState({
        visible:true,
        alertVisitble: true,
        msgAlert: 'Vui lòng chọn đơn vị.',
        typeAlert: 'error'
      },()=>setTimeout(() => this.setState({
        alertVisitble: false
      }), 3000));
      return;
    }
    let mapping = {};
    [mapping.tch_id, mapping.brand] = this.parseSelectStore(selectedStore);
    mapping.hr_department_id = selectedDepartment;
      if (!this.state.isEditting) {
      this.postMapping(mapping);
    } else {
      mapping._id=this.state.idEditing;
      if(this.state.selectedDepartmentEditting==mapping.hr_department_id
        && this.state.selectedStoreEditting==mapping.tch_id){
          this.setState({
            visible:false,
            alertVisitble: true,
            selectedDepartment: 0,
            selectedStore: 0,
            msgAlert: 'Sửa mapping thành công.',
            typeAlert: 'success'
          },()=>setTimeout(() => this.setState({
            alertVisitble: false
          }), 3000));
          return;
        }
      this.putMapping(mapping);
    }
    
    this.setState({
      ModalText: 'The modal will be closed after success',
      confirmLoading: true,
    });
  }

  handleCancel = () => {
    console.log('Clicked cancel button');
    this.setState({
      visible: false,
      selectedDepartment: 0,
      selectedStore: 0
    });
  }
  //====================== End Modal ===============================//

  getStores = () => {
    let _this = this;
    _this.setState({
      isLoadingData: true
    });
    StoreModel.getList(function (data) {
      _this.props.getStoreList(data);
      _this.getDepartments();
    },
    err => {
      _this.setState({
        alertVisitble: true,
        typeAlert: 'error',
        msgAlert: `${err}`
      })
      _this.getDepartments(err);
    },
    () => {});
  }

  getDepartments = errStore => {
    let _this = this;
    DepartmentModel.getList(data => {
      _this.props.getDepartmentList(data);
      _this.getMapping(errStore, null);
    },
    err => {
      _this.setState({
        alertVisitble: true,
        typeAlert: 'error',
        msgAlert: `${err}`
      });
      _this.getMapping(errStore, err);
    },
    () => {});
  };
  parseSelectStore = (data) => {
    if(data){
      return  [parseInt(data.split("#")[0]),data.split("#")[1]];
    }
    else{
        return [0,'TCH'];
    }
    
  }
  formatFilter = () => {
    const {filterStore, filterDepartment} = this.state;
    let filter = {};
    if (filterStore) {
      [filter.store_id, filter.brand] = this.parseSelectStore(filterStore);
    }

    if (filterDepartment) {
      filter.hr_department_id = filterDepartment
    }
    return filter
  };
  resetFilter=()=>{
    this.setState({filterDepartment:0,filterStore:0},()=>this.getMapping())
  }
  getMapping = (errStore, errDepartment) => {
    const _this = this;
    let filter = this.formatFilter();
    MappingModel.getList(filter, data => {
      _this.props.getMappingList(data);
      if (errStore || errDepartment) {
      // if (errDepartment) {
        _this.setState({
          alertVisitble: true,
          typeAlert: 'error',
          msgAlert: errStore && errDepartment 
                    ? `Stores list: ${errStore}. Departments list: ${errDepartment}`
                    : errStore && !errDepartment
                      ? `Stores list: ${errStore}`
                      : `Departments list: ${errDepartment}`
        })
      }
    }, 
    err => {
      _this.setState({
        alertVisitble: true,
        typeAlert: 'error',
        msgAlert: errStore && errDepartment 
                  ? `Mapping list: ${err}. Stores list: ${errStore}. Departments list: ${errDepartment}`
                  : errStore && !errDepartment
                    ? `Mapping list: ${err}. Stores list: ${errStore}`
                    : errDepartment && !errStore
                      ? `Mapping list: ${err}. Stores list: ${errDepartment}`
                      : `Mapping list: ${err}`
      })
    }, 
    () => {
      if (_this.state.alertVisitble === true) {
        setTimeout(() => _this.setState({
          alertVisitble: false
        }), 3000)
      }
      _this.filterData();
      _this.setState({
        isLoadingData: false
      })
    });
  }

  postMapping = mapping => {
   
    let _this = this;
    MappingModel.postMapping(
      mapping, 
      data => {
        _this.props.actAddMapping(data);
        _this.setState({
          visible: false,
          selectedDepartment: 0,
          selectedStore: 0,
          alertVisitble: true,
          msgAlert: 'Thêm mapping thành công',
          typeAlert: 'success'
        });
      },
      err => {
        let message = lodash.get(err, 'response.data.message', []);
        message = message.join(" ");
        _this.setState({
          alertVisitble: true,
          msgAlert: `Thêm mapping thất bại. Chi tiết: ${message}`,
          typeAlert: 'error'
        })
      },
      () => {
        _this.setState({
          confirmLoading: false,
        });
        _this.getMapping();
      }
    )
  }

  putMapping = mapping => {
    let _this = this;
    MappingModel.putMapping(
      mapping, 
      data => {
        _this.props.actEditMapping(data);
        this.setState({
          visible: false,
          selectedDepartment: 0,
          selectedStore: 0,
          alertVisitble: true,
          msgAlert: 'Sửa mapping thành công',
          typeAlert: 'success'
        })
      },
      err => {
        _this.setState({
          alertVisitble: true,
          msgAlert: `Sửa mapping thất bại. Chi tiết: ${err.response.data.message.join()}`,
          typeAlert: 'error'
        })
      },
      (e) => {
        _this.setState({
          confirmLoading: false
        });
        _this.getMapping();
      }
    )
  }

  deleteMapping = id => {
    let _this = this;
    MappingModel.deleteMapping(id, data => {
      _this.props.actDeleteMapping(data);
      _this.setState({
        alertVisitble: true,
        msgAlert: 'Xóa mapping thành công',
        typeAlert: 'success'
      })
    },
    err => {
      _this.setState({
        alertVisitble: true,
        msgAlert: `Xóa mapping thất bại. Chi tiết: ${err}`,
        typeAlert: 'error'
      })
    },
    () => {
      _this.getMapping();
    })
  }

  componentDidMount() {
    this.getStores();
    // this.getDepartments();
    // this.getMapping();
  }

  componentDidUpdate() {
  }

  render() {
    const { store, department } = this.props;
    const { 
      visible, 
      confirmLoading, 
      isEditting, 
      filterData, 
      alertVisitble, 
      typeAlert, 
      msgAlert, 
      selectedDepartment, 
      selectedStore,
      isLoadingData,
      filterDepartment,
      filterStore,
    } = this.state;
    return (
      <>
        {isLoadingData &&
        <LoadingIndex />
        }
        {!isLoadingData &&
          <AdminLayout>
            <Layout>
              <div className="app-heading">
                <AdminContentHeader text="Danh sách mapping cửa hàng và đơn vị" />
                <Col className="gutter-row" span={12}>
                  <div className="gutter-box text-right">
                    <Button onClick={this.showModal} type="primary" ghost><Icon type="plus-circle" theme="twoTone" /> Thêm</Button>
                  </div>
                </Col>
              </div>
              <div className="app-content">
                <Col className="gutter-row" span={6}>
                  <div className="gutter-box margin-right-15">
                  {filterStore!==0 &&
                    <Select
                      showSearch
                      className="width-100-per"
                      placeholder="Tất cả"
                      defaultValue={filterStore==0?undefined:filterStore}
                      optionFilterProp="children"
                      onChange={this.handleChangeFilterStore}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      filterOption={(input, option) => {
                        let optionData = option.props.children;
                        if (typeof option !==  'string') {
                          optionData = optionData.join('');
                        }
                        return optionData.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }}
                    >
                      {store && store.map(s => 
                        <Option key={s.id + "#" + s.brand} value={s.id + "#" + s.brand}>{s.name} - [{s.brand}]</Option>
                      )}
                    </Select>
                  }
                  {filterStore===0 &&
                    <Select
                      showSearch
                      className="width-100-per"
                      placeholder="Tất cả"
                      optionFilterProp="children"
                      onChange={this.handleChangeFilterStore}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      filterOption={(input, option) => {
                        let optionData = option.props.children;
                        if (typeof option !==  'string') {
                          optionData = optionData.join('');
                        }
                        return optionData.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }}
                    >
                      {store && store.map(s => 
                        <Option key={s.id + "#" + s.brand} value={s.id + "#" + s.brand}>{s.name} - [{s.brand}]</Option>
                      )}
                    </Select>
                  }
                  </div>
                </Col>

                <Col className="gutter-row" span={6}>
                  <div className="gutter-box margin-right-15">
                  {filterDepartment!==0 &&
                    <Select
                      showSearch
                      className="width-100-per"
                      placeholder="Tất cả"
                      optionFilterProp="children"
                      defaultValue={filterDepartment==0?undefined:filterDepartment}
                      onChange={this.handleChangeFilterDepartment}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      filterOption={(input, option) => {
                        let optionData = option.props.children;
                        if (typeof option !==  'string') {
                          optionData = optionData.join('');
                        }
                        return optionData.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }}
                    >
                      {department && department.map(d => 
                        <Option key={d.id} value={d.id}>{d.name} - [{d.parentName}]</Option>
                      )}
                    </Select>
                  }
                  {filterDepartment===0 &&
                    <Select
                      showSearch
                      className="width-100-per"
                      placeholder="Tất cả"
                      optionFilterProp="children"
                      onChange={this.handleChangeFilterDepartment}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      filterOption={(input, option) => {
                        let optionData = option.props.children;
                        if (typeof option !==  'string') {
                          optionData = optionData.join('');
                        }
                        return optionData.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }}
                    >
                      {department && department.map(d => 
                        <Option key={d.id} value={d.id}>{d.name} - [{d.parentName}]</Option>
                      )}
                    </Select>
                  }
                  </div>
                </Col>
                <Col className="gutter-row" span={3}>
                  <div className="gutter-box">
                    <Button type="default" onClick= {() => this.resetFilter()}><Icon type="filter" theme="twoTone" /> Đặt lại bộ lọc</Button>
                  </div>
                </Col>
                <Col className="gutter-row" span={6}>
                  <div className="gutter-box">
                    <Button type="primary" onClick= {() => this.getMapping()}><Icon type="filter" theme="twoTone" /> Áp dụng bộ lọc</Button>
                  </div>
                </Col>
                <div className="clear"></div>
              </div>

              <div className="app-content">
                <div>
                  <Table bordered size="middle" locale={{emptyText: 'Không có dữ liệu.'}} columns={this.columns()} dataSource={filterData} pagination={false} />
                </div>
                <div className="clear"></div>
              </div>

              <MappingModal 
                store={store}
                department={department}
                visible={visible}   
                confirmLoading={confirmLoading}
                handleOk={this.handleOk}
                handleCancel={this.handleCancel}
                isEditting={isEditting}
                handleChangeSelectStore={this.handleChangeSelectStore}
                handleChangeSelectDepartment={this.handleChangeSelectDepartment}
                selectedDepartment={selectedDepartment}
                selectedStore={selectedStore}
              />

              {alertVisitble && 
                <AlertBox
                  message={msgAlert}
                  type={typeAlert}
                />
              }
            </Layout>
          </AdminLayout>
        }
      </>
    );
  }
}

MappingIndexs.propTypes = propTypes;
const mapStateToProps = state => ({
  store: state.store,
  department: state.department,
  mapping: state.mapping
});
const mapDispatchToProps = dispatch => ({
  getStoreList: bindActionCreators(actStoreList, dispatch),
  getDepartmentList: bindActionCreators(actDepartmentList, dispatch),
  actAddMapping: bindActionCreators(actAddMapping, dispatch),
  actEditMapping: bindActionCreators(actEditMapping, dispatch),
  actDeleteMapping: bindActionCreators(actDeleteMapping, dispatch),
  getMappingList: bindActionCreators(actGetMapping, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MappingIndexs);
