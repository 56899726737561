import React, { Component } from 'react';
import {Modal, Select, Col} from 'antd';
import PropTypes from 'prop-types';
import lodash from 'lodash';

const propsTypes = {
  DepartmentExceptionModal: PropTypes.arrayOf(
    PropTypes.shape({
      department_id: PropTypes.number.isRequired,
      type: PropTypes.number.isRequired,
    })
  ).isRequired, // store nhan vao phai la array .isRequied la yeu cau phai co
  selectedDepartment: PropTypes.number.isRequired,
  selectedType:PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  confirmLoading: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isEditting: PropTypes.bool.isRequired,
  handleChangeSelectDepartment: PropTypes.func.isRequired,
  handleChangeSelectType: PropTypes.func.isRequired,
  handleSaveIdEditing: PropTypes.func.isRequired,
}

//======================= Select ===================================//
const Option = Select.Option;
// function handleChange(value) {
//   console.log(`selected ${value}`);
// }
function handleBlur() {
  // console.log('blur');
}
function handleFocus() {
  // console.log('focus');
}
//===================== End Select ================================//

class DepartmentExceptionModal extends Component {
  render() {
    const {
      visible, 
      confirmLoading, 
      handleOk, 
      handleCancel, 
      department, 
      isEditting, 
      handleChangeSelectDepartment, 
      handleChangeSelectType,
      selectedDepartment,
      selectedType,
    } = this.props;
    
    return (
      <Modal title={isEditting ? 'Sửa đơn vị ngoại lệ' : 'Thêm đơn vị ngoại lệ'}
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
          >
            <div className="padding-bottom-15">
            
              <Col className="gutter-row" span={12}>
                <div className="gutter-box margin-left-7">
                  <label className="text-title-field col-md-12">
                  Đơn vị <span className="text-danger">*</span>
                </label>
                {selectedDepartment !== 0 &&
                    <Select
                    showSearch
                    style={{ width: 200 }}
                    className="width-100-per"
                    defaultValue={selectedDepartment}
                    onChange={handleChangeSelectDepartment}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    placeholder="Đơn vị"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                  {department && department.map(s => 
                    <Option key={s.id} value={s.id}>{s.name}</Option>
                  )}
                  </Select>
                  }
                  {selectedDepartment === 0 &&
                    <Select
                      showSearch
                      className="width-100-per"
                      placeholder="Đơn vị"
                      className="width-100-per"
                      optionFilterProp="children"
                      onChange={handleChangeSelectDepartment}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {department && department.map(s => 
                        <Option key={s.id} value={s.id}>{s.name}</Option>
                      )}
                    </Select>
                  }
                </div>
              </Col>
              <Col className="gutter-row" span={12}>
                <div className="gutter-box margin-left-7">
                <label className="text-title-field col-md-12">
                  Loại ngoại lệ <span className="text-danger">*</span>
                </label>
                {selectedType !== 0 && 
                <Select
                  style={{ width: 200 }}
                  defaultValue={selectedType}
                  className="width-100-per"
                  placeholder="Loại ngoại lệ"
                  optionFilterProp="children"
                  onChange={handleChangeSelectType}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value={0}>Vui lòng chọn</Option>
                  <Option value={1}>Loại không có bảo vệ</Option>
                </Select>
                }
                {selectedType === 0 &&
                    <Select
                      showSearch
                      className="width-100-per"
                      placeholder="Loại ngoại lệ"
                      optionFilterProp="children"
                      onChange={handleChangeSelectType}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        <Option value={0}>Vui lòng chọn</Option>
                        <Option value={1}>Loại không có bảo vệ</Option>
                    </Select>
                  }
                </div>
              </Col>
            </div>
            <br />
            <div style={{height:100}}></div>
          </Modal>
    );
  }
}

DepartmentExceptionModal.propTypes = propsTypes;

export default DepartmentExceptionModal;