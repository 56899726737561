import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actStoreList } from 'actions/admin/store';
import lodash from 'lodash';
import { actDepartmentList } from '../../../../actions/admin/department';
import Select from 'antd/lib/select';
import PropTypes from 'prop-types';
import { Col, Tooltip } from 'antd';
// import { Pagination, Button } from 'antd';
import { Button,Row } from 'antd';
import {Modal, Table, Divider } from 'antd';
import { Layout, Icon } from 'antd';
import AdminLayout from '../../admin-layout.js';
import AdminContentHeader from '../../admin-content-header.js';
import DepartmentModel from '../../../../model/department';
import AlertBox from '../../../../component/alertbox/alertbox';
import LoadingIndex from '../../loading/container/index';
import DepartmentExceptionModel from '../../../../model/departmentException';
import DepartmentExceptionModal from '../DepartmentExceptionModal/DepartmentExceptionModal';
import * as XLSX from 'xlsx';
const propTypes = {
  jobtitle: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  getJobtitleList: PropTypes.func.isRequired,
  actAddDepartmentException: PropTypes.func.isRequired,
  actEditDepartmentException: PropTypes.func.isRequired,
  actDeleteDepartmentException: PropTypes.func.isRequired,
  getDepartmentExceptionList: PropTypes.func.isRequired
}



const make_cols = refstr => {
  let o = [], C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i }
  return o;
};
//======================= Select ===================================//
const Option = Select.Option;
const confirm = Modal.confirm;
// function handleChange(value) {
//   console.log(`selected ${value}`);
// }
function handleBlur() {
  // console.log('blur');
}
function handleFocus() {
  // console.log('focus');
}
//===================== End Select ================================//

//===================== Pagination ================================//
// function showTotal(total) {
//   return `Hiện thị ${total} trong tổng số ${total}`;
// }
// function onShowSizeChange(current, pageSize) {
//   // console.log(current, pageSize);
// }
//==================== End Pagination =============================//

class DepartmentException extends Component {
  //===================== Start modal =============================//
  state = {
    ModalText: 'Content of the modal',
    visible: false,
    confirmLoading: false,
    isEditting: false,
    selectedDepartment: 0,
    selectedType: 0,
    filterDepartment: 0,
    filterData: [],
    alertVisitble: false,
    typeAlert: 'success',
    msgAlert: 'Success',
    queryMapping: '',
    isLoadingData: true,
    jobtitles: [],
    departmentExceptions: [],
    departments:[],
    id_editing:null,
  }

  handleChangeSelectType= id => {
    this.setState({
      selectedType: id
    });
  }

  handleChangeSelectDepartment= id => {
    this.setState({
      selectedDepartment: id
    });
  }


  handleChangeFilterDepartment = id => {
    this.setState({
      filterDepartment: id
    })
  }

  showModal = e => {
    if (e) {
      this.setState({
        isEditting: false
      })
    }
    this.setState({
      visible: true,
    });
  }

  changeIsEdittingStatus = () => {
    this.setState({
      isEditting: true
    })
  }
 showConfirm=(_id)=> {
   var _this=this;
        confirm({
          content: "Bạn có thực sự muốn xóa dòng này ?",
          title:"Thông báo",
          onOk() {
            _this.deleteDepartmentException(_id);
          },
          onCancel() {
            //console.log('Cancel');
          },
        });
  }
  //====================== Start Table ==============================//
  columns() {
    let data = [{
      title: 'Tên viết tắt',
      dataIndex: 'departmentCode',
      key: 'departmentCode',
      width:'20%',
      
    }, {
      title: 'Tên đơn vị',
      dataIndex: 'departmentName',
      key: 'departmentName',
      width:'35%',
    },
    {
      title: 'Loại ngoại lệ',
      dataIndex: 'type',
      key: 'type',
      width:'10%',
      render: text => <a>{text == 1 ? "Không có bảo vệ":""}</a>,
    },{
      title: '',
      key: 'action',
      width:'5%',
      render: (text, record) => (
        <span className="text-center">
          <Tooltip placement="left" title="Xoá">
            <span
              onClick={() => this.showConfirm(record._id)}
              style={{ color: '#1890ff', cursor: 'pointer' }}
            >
              <Icon type="delete" />
            </span>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip placement="left" title="Chỉnh sửa">
            <span
              onClick={() => {
                this.showModal();
                this.changeIsEdittingStatus();
                this.handleChangeSelectDepartment(record.department_id);
                this.handleChangeSelectType(record.type);
                this.handleSaveIdEditing(record._id);
              }}
              style={{ color: '#1890ff', cursor: 'pointer' }}
            >
              <Icon type="edit" theme="twoTone" />
            </span>
          </Tooltip>

        </span>
      ),
    }];
    return data;
  }
  filterData = () => {
    const { mapping, store, department } = this.props;

    let filteredMapping = mapping.mappingList ? mapping.mappingList : [];

    const data = filteredMapping.map(m => {
      let storeMap = lodash.find(store, { 'id': m.store_id, 'brand': m.brand });
      let depart = lodash.find(department, ['id', m.hr_department_id]);
      let data = {
        key: `${m._id}${m.hr_department_id}`,
        tch_id: m.store_id,
        brand: m.brand,
        _id: m._id,
        hr_department_id: m.hr_department_id,
        storeName: lodash.get(storeMap, 'name', '') + ' - [' + lodash.get(storeMap, 'brand', '') + ']',
        departmentName: lodash.get(depart, 'name', '') + ' - [' + lodash.get(depart, 'parentName', '') + ']'
      };

      return data;
    });

    this.setState({
      filterData: data
    })
  }

  //==================================== End Table ==============================//

  handleOk = () => {
    const {id_editing, selectedDepartment, selectedType } = this.state;
    if(selectedDepartment==null||selectedDepartment==0){
      this.setState({
        visible:true,
        alertVisitble: true,
        msgAlert: 'Vui lòng chọn đơn vị.',
        typeAlert: 'error'
      },()=>setTimeout(() => this.setState({
        alertVisitble: false,
      }), 3000));
      return;
    }
    if(selectedType==null||selectedType==0){
      this.setState({
        visible:true,
        alertVisitble: true,
        msgAlert: 'Vui lòng chọn loại ngoại lệ.',
        typeAlert: 'error'
      },()=>setTimeout(() => this.setState({
        alertVisitble: false,
      }), 3000));
      return;
    }
    let object={_id:id_editing, department_id:selectedDepartment,type:selectedType}
    if (!this.state.isEditting) {
      this.postDepartmentException(object);
    } else {
      this.putDepartmentException(object);
    }

    this.setState({
      ModalText: 'The modal will be closed after success',
      confirmLoading: true,
    });
  }

  handleCancel = () => {
    console.log('Clicked cancel button');
    this.setState({
      visible: false,
      selectedDepartment: 0,
      selectedType: 0,
      inputLevel:0,
      selectedTypeBonus:0,
      id_editing:null,
    });
  }
  //====================== End Modal ===============================//
  resetFilter=()=>{
    this.setState({filterDepartment:0},()=>this.getListDepartmentExceptions())
  }

  getListDepartmentExceptions = () => {
    let _this = this;
    _this.setState({
      isLoadingData: true
    });
    DepartmentExceptionModel.getList({department_id:_this.state.filterDepartment==0?null:_this.state.filterDepartment},function (data) {
      var dataRe = data.items.map(a => {
        var department = _this.state.departments.filter(b => b.id == a.department_id)[0]
        a.departmentCode = department ? department.code : '';
        a.departmentName=department?department.name:'';
        return a;
      })
      _this.setState({ departmentExceptions: dataRe,isLoadingData:false });
    },
      err => {
        _this.setState({
          alertVisitble: true,
          typeAlert: 'error',
          msgAlert: `${err}`
        })
      },
    () => {
      if (_this.state.alertVisitble === true) {
        setTimeout(() => _this.setState({
          alertVisitble: false
        }), 3000)
      }
      _this.setState({
        isLoadingData: false
      })
    });
  }

  getDepartments = errStore => {
    let _this = this;
    DepartmentModel.getList(data => {
      this.setState({departments:data.items})
      this.getListDepartmentExceptions();
    },
      err => {
        this.setState({
          alertVisitble: true,
          typeAlert: 'error',
          msgAlert: `${err}`
        });
        //_this.getMapping(errStore, err);
      },
      () => { });
  };


  parseSelectStore = (data) => {
    let arrFilter = data.split("#");
    return arrFilter;
  }
  formatFilter = () => {
    const { filterStore, filterDepartment } = this.state;
    let filter = {};  
    if (filterStore) {
      [filter.store_id, filter.brand] = this.parseSelectStore(filterStore);
    }

    if (filterDepartment) {
      filter.hr_department_id = filterDepartment
    }
    return filter
  };
  getMapping = (errStore, errDepartment) => {
    const _this = this;
    let filter = this.formatFilter();
    DepartmentExceptionModal.getList(filter, data => {
      _this.props.getMappingList(data);
      if (errStore || errDepartment) {
        // if (errDepartment) {
        _this.setState({
          alertVisitble: true,
          typeAlert: 'error',
          msgAlert: errStore && errDepartment
            ? `Stores list: ${errStore}. Departments list: ${errDepartment}`
            : errStore && !errDepartment
              ? `Stores list: ${errStore}`
              : `Departments list: ${errDepartment}`
        })
      }
    },
      err => {
        _this.setState({
          alertVisitble: true,
          typeAlert: 'error',
          msgAlert: errStore && errDepartment
            ? `Mapping list: ${err}. Stores list: ${errStore}. Departments list: ${errDepartment}`
            : errStore && !errDepartment
              ? `Mapping list: ${err}. Stores list: ${errStore}`
              : errDepartment && !errStore
                ? `Mapping list: ${err}. Stores list: ${errDepartment}`
                : `Mapping list: ${err}`
        })
      },
      () => {
        if (_this.state.alertVisitble === true) {
          setTimeout(() => _this.setState({
            alertVisitble: false
          }), 3000)
        }
        _this.filterData();
        _this.setState({
          isLoadingData: false
        })
      });
  }

  postDepartmentException = departmentExcepModel => {
    let _this = this;
    DepartmentExceptionModel.Add(

      departmentExcepModel,
      data => {
        _this.setState({
          visible: false,
          selectedDepartment: 0,
          selectedType: 0,
          selectedTypeBonus:0,
          inputLevel:null,
          id_editing:null,
          alertVisitble: true,
          msgAlert: 'Thêm đơn vị ngoại lệ thành công',
          typeAlert: 'success'
        });
      },
      err => {
        let message = lodash.get(err, 'response.data.message', []);
        message = message.join("<br>");
        _this.setState({
          alertVisitble: true,
          msgAlert: `Thêm đơn vị ngoại lệ thất bại. Chi tiết: ${message}`,
          typeAlert: 'error'
        })
      },
      () => {
        _this.setState({
          confirmLoading: false,
        });
        _this.getListDepartmentExceptions();
      }
    )
  }

  handleSaveIdEditing = id =>{
    this.setState({id_editing : id})
  }

  putDepartmentException = departmentExcepModel => {
    let _this = this;
    DepartmentExceptionModel.Update(
      departmentExcepModel,
      data => {
        this.setState({
          visible: false,
          selectedBrand: 0,
          selectedJobtitle: 0,
          inputLevel:null,
          id_editing:null,
          alertVisitble: true,
          msgAlert: 'Sửa đơn vị ngoại lệ thành công',
          typeAlert: 'success'
        })
      },
      err => {
        _this.setState({
          alertVisitble: true,
          msgAlert: `Sửa đơn vị ngoại lệ thất bại. Chi tiết: ${err.response.data.message.join()}`,
          typeAlert: 'error'
        })
      },
      () => {
        _this.setState({
          confirmLoading: false
        });
        _this.getListDepartmentExceptions();
      }
    )
  }

  deleteDepartmentException = id => {
    let _this = this;
    DepartmentExceptionModel.Delete(id, data => {
      _this.setState({
        alertVisitble: true,
        msgAlert: 'Xóa đơn vị ngoại lệ thành công',
        typeAlert: 'success'
      })
    },
      err => {
        _this.setState({
          alertVisitble: true,
          msgAlert: `Xóa đơn vị ngoại lệ thất bại. Chi tiết: ${err}`,
          typeAlert: 'error'
        })
      },
      () => {
        _this.getListDepartmentExceptions();
      })
  }

  componentDidMount() {
    this.getDepartments();
    // this.getMapping();
  }

  componentDidUpdate() {
  }
  handleFile(fileUpload) {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    const _this = this;
    let file = fileUpload.target.files[0];
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', cellDates: true, cellNF: false, cellText: false });
      /* Get first worksheet */
      for (var i = 0; i < wb.SheetNames.length; i++) {
        const wsname = wb.SheetNames[i];
        const ws = wb.Sheets[wsname];
        let data = [];
        data = XLSX.utils.sheet_to_json(ws, { header: 1, dateNF: "DD-MM-YYYY" });
        let cols = make_cols(ws['!ref']);
        //    console.log('change', Utils.removeAccents(file.name.split(" ")))
        /* Update state */
        let dataPost = [];
        for (let j = 1; j < data.length; j++) {
          if(data[j][0]){
            var type=data[j][1]&&data[j][1].toLowerCase()=="không có bảo vệ"?1:0;
            var object = { department_id: data[j][0],type:type };
            dataPost.push(object);
          }
        }
        _this.setState({ dataImport: dataPost })
      }
      /* Convert array of arrays */

    };
    if (rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
  };
  postdata = () => {
    if(this.state.dataImport.length<=0){
      this.setState({
        alertVisitble: true,
        typeAlert: 'error',
        msgAlert: `Không có dữ liệu, vui lòng chọn file có dữ liệu`
      },()=>setTimeout(() => this.setState({
        alertVisitble: false,
      }), 3000));
      return;
    }
    var _this=this;
    _this.setState({
      alertVisitble: true,
      typeAlert: 'success',
      msgAlert: `Đang lưu dữ liệu vui lòng đợi`
    },()=>setTimeout(() => this.setState({
      alertVisitble: false,
    }), 3000));
    DepartmentExceptionModel.AddRaw(null, this.state.dataImport, rsp => {
      document.getElementById("inputfile").value = "";
      this.getListDepartmentExceptions();
      _this.setState({
        dataImport:[],
        alertVisitble: true,
        typeAlert: 'success',
        msgAlert: `Thêm dữ liệu thành công`
      },()=>setTimeout(() => this.setState({
        alertVisitble: false,
      }), 3000));
    }, (error) => {
      this.setState({
        alertVisitble: true,
        typeAlert: 'error',
        msgAlert: `Thêm dữ liệu thất bại`
      },()=>setTimeout(() => this.setState({
        alertVisitble: false,
      }), 3000));
    }, () => { })
  }
   handleClickExportTemplate() {
    const wb = XLSX.utils.book_new();
        let header = [['ID  đơn vị*','Loại ngoại lệ *']];
        /* convert state to workbook */
        const ws = XLSX.utils.aoa_to_sheet(header);


        //if (!wb.Props) wb.Props = {};
        //ws['B1'] = {
        //    v: 'Danh sách Nhân Viên',
        //    t: 's',
        //};
        XLSX.utils.book_append_sheet(
            wb,
            XLSX.utils.aoa_to_sheet(header),
            "đơn vị ngoại lệ"
        );


    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "[APP-TINHTHUONG_TCH]donvingoaile.xlsx");
}
  render() {
    const { departmentException } = this.props;
    const {
      visible,
      confirmLoading,
      isEditting,
      filterData,
      alertVisitble,
      typeAlert,
      msgAlert,
      selectedBrand,
      selectedJobtitle,
      inputLevel,
      isLoadingData
    } = this.state;
    return (
      <>
        {isLoadingData &&
          <LoadingIndex />
        }
        {!isLoadingData &&
          <AdminLayout>
            <Layout>
              <div className="app-heading">
                <AdminContentHeader text="Thiết lập đơn vị ngoại lệ." />
                <Row type="flex" justify="end">
                <Col className="gutter-row" xs={3}>
                  <div className="gutter-box text-right">
                    <Button onClick={this.showModal} type="primary" ghost><Icon type="plus-circle" theme="twoTone" /> Thêm</Button>
                  </div>
                </Col>
                </Row>
              </div>
              <div className="app-content">
              <Row type="flex" justify="start">
            <Col className="gutter-row" span={6}>
              <input style={{cursor:"pointer"}} type='file' id="inputfile" className='' onChange={this.handleFile.bind(this)} />
            </Col>
            <Col className="gutter-row" xl={4} xs={8} lg={4} xxl={3}  >
            <div className="gutter-box">
                <Button type="default" onClick={() => this.handleClickExportTemplate()}><Icon type="download" />Xuất template mẫu</Button>
              </div>
            </Col>
            <Col className="gutter-row" xs={3} >
            <div className="gutter-box">
                <Button type="primary" onClick={() => this.postdata()}><Icon type="save" theme="twoTone" />Lưu dữ liệu</Button>
              </div>
            </Col>
            </Row>
            <div className="clear"></div>
          </div>
              <div className="app-content">
                <Col className="gutter-row" span={6}>
                  <div className="gutter-box margin-right-15">
                    <Select
                      showSearch
                      className="width-100-per"
                      placeholder="Tất cả"
                      defaultValue={this.state.filterDepartment==0?undefined:this.state.filterDepartment}
                      optionFilterProp="children"
                      onChange={this.handleChangeFilterDepartment}
                      onFocus={handleFocus} 
                      onBlur={handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {this.state.departments && this.state.departments.map(d =>
                        <Option key={d.id} value={d.id}>{d.name}</Option>
                      )}
                    </Select>
                  </div>
                </Col>
                <Col className="gutter-row" span={3}>
                  <div className="gutter-box">
                    <Button type="default" onClick= {() => this.resetFilter()}><Icon type="filter" theme="twoTone" /> Đặt lại bộ lọc</Button>
                  </div>
                </Col>
                <Col className="gutter-row" span={6}>
                  <div className="gutter-box">
                    <Button type="primary" onClick={() => this.getListDepartmentExceptions()}><Icon type="filter" theme="twoTone" /> Áp dụng bộ lọc</Button>
                  </div>
                </Col>
                <div className="clear"></div>
              </div>

              <div className="app-content">
                <div>
                  <Table bordered size="middle" locale={{emptyText: 'Không có dữ liệu.'}} columns={this.columns()} dataSource={this.state.departmentExceptions} pagination={false} />
                </div>
                <div className="clear"></div>
              </div>

              <DepartmentExceptionModal
                department={this.state.departments}
                departmentException={departmentException}
                visible={visible}
                confirmLoading={confirmLoading}
                handleOk={this.handleOk}
                handleCancel={this.handleCancel}
                isEditting={isEditting}
                handleChangeSelectDepartment={this.handleChangeSelectDepartment}
                handleChangeSelectType={this.handleChangeSelectType}
                selectedDepartment={this.state.selectedDepartment}
                selectedType={this.state.selectedType}
                handleSaveIdEditing={this.handleSaveIdEditing}
              />

              {alertVisitble &&
                <AlertBox
                  message={msgAlert}
                  type={typeAlert}
                />
              }
            </Layout>
          </AdminLayout>
        }
      </>
    );
  }
}

DepartmentException.propTypes = propTypes;
const departmentExceptionStateToProps = state => ({

});
const departmentExceptionDispatchToProps = dispatch => ({
 
});

export default connect(departmentExceptionStateToProps, departmentExceptionDispatchToProps)(DepartmentException);
