import BaseModel from 'model/base.js'
const apiUrl = 'api';
const JobitleLevelModel = {
  getListJobtitle: (sucCallback, errCallback, finCallback) => {
    let url = 'api/hara-work/jobtitles?isGetAll=true'
    BaseModel.get(url, null, function(data) {
      sucCallback(data.data);
    },
    err => errCallback(err),
    () => finCallback())
  },

  getList: (filter,sucCallback, errCallback, finCallback) => {
    let url = 'api/jobtitlelevel/jobtitlelevels'
    BaseModel.get(url, filter, function(data) {
      sucCallback(data.data);
    },
    err => errCallback(err),
    () => finCallback())
  },
  postJobtitleLevel: (data, sucCallback, errCallback, finCallback) => {
    let url = `${apiUrl}/jobtitlelevel/add`;
    BaseModel.post(
      url, 
      data, 
      res => {
        sucCallback(res)
      }, 
      err => {
        errCallback(err)
      },
      () => finCallback()
    )
  },
  putJobtitleLevel: (data, sucCallback, errCallback, finCallback) => {
    let url = `${apiUrl}/jobtitlelevel/update`;
    BaseModel.put(url, data, function(res) {
      sucCallback(res);
    },
    err => errCallback(err),
    () => finCallback())
  },
  deleteJobtitleLevel: (jobtitlelevelId, sucCallback, errCallback, finCallback) => {
    let url = `${apiUrl}/jobtitlelevel/delete`;
    BaseModel.delete(url,{_id:jobtitlelevelId}, function(res) {
      sucCallback(res);
    },
    err => errCallback(err),
    () => finCallback())
  }
}

export default JobitleLevelModel;