/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Table, Layout, Switch } from 'antd';
import {actSalaryList} from '../../../../actions/admin/salaries';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AdminLayout from 'modules/admin/admin-layout.js';
import AlertBox from '../../../../component/alertbox/alertbox';
import AdminContentHeader from 'modules/admin/admin-content-header.js';
import SalaryModel from '../../../../model/salary-element';
import _ from 'lodash';

class SalaryIndexs extends Component {
  //===================== Start Table =============================//
  state = {
    salaries: null,
    ModalText: 'Content of the modal',
    visible: false,
    typeAlert: 'success',
    msgAlert: 'Success',
    confirmLoading: false,
  }

  listSalary = function(query) {
    return new Promise((resolve, reject) => {
      SalaryModel.getList(query, (dataSalaries) => {
        resolve(dataSalaries);
      }, (error) =>{
        reject(error);
      });
    });
  }

  async componentDidMount() {
    let query = {isGetAll: true};
    let arrSalary = [];
    try {
      let data = await this.listSalary(query);
      arrSalary = _.get(data, 'items', []);
    } catch (error) {
      this.setState({visible: true, typeAlert: 'error', msgAlert: 'Error'});
      setTimeout(() => {
        this.setState({visible: false});
      }, 3000);
    }

    SalaryModel.getChooseSalary((dataChoose) => {
      let chooseId = _.get(dataChoose, '_id', '');
      if (arrSalary) {
        arrSalary.forEach(salary => {
          if (chooseId && salary.id === chooseId) {
            salary.isSwitch = true;
          } else {
            salary.isSwitch = false;
          }
        });
        this.setState({salaries: arrSalary});
      }
    }, (error) => {
      this.setState({visible: true, typeAlert: 'error', msgAlert: 'Error'});
      setTimeout(() => {
        this.setState({visible: false});
      }, 3000);
    });
  }

  handleSwitchChange(record) {
    let chooseData = '';
    let lstSalary = this.state.salaries;
    let objIndex = lstSalary.findIndex(salary => salary.id === record.id);
    lstSalary[objIndex].isSwitch = !lstSalary[objIndex].isSwitch;

    if (lstSalary[objIndex].isSwitch) {
      chooseData = {id: record.id};
      SalaryModel.chooseSalary(chooseData, true, (data) => {
        if (!data) {
          return false;
        }
        lstSalary.forEach(salary => {
            if (salary.id !== record.id) {
              salary.isSwitch = false;
            }
        });
        this.setState({salaries: lstSalary, visible: true, typeAlert: 'success', msgAlert: 'Success'});
        setTimeout(() => {
          this.setState({visible: false});
        }, 3000);
      }, (error) => {
        this.setState({visible: true, typeAlert: 'error', msgAlert: 'Error'});
        setTimeout(() => {
          this.setState({visible: false});
        }, 3000);
      });
    } else {
      SalaryModel.chooseSalary(chooseData, false, (data) => {
        this.setState({salaries: lstSalary, visible: true, typeAlert: 'success', msgAlert: 'Success'});
        setTimeout(() => {
          this.setState({visible: false});
        }, 3000);
      }, (error) => {
        this.setState({visible: true, typeAlert: 'error', msgAlert: 'Error'});
        setTimeout(() => {
          this.setState({visible: false});
        }, 3000);
      });
    }
  }

  columns() {
    let data = [{
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
    }, {
      title: 'Mã',
      dataIndex: 'code',
      key: 'code',
      render: text => <a>{text}</a>,
    }, {
      title: '',
      key: 'action',
      width: 70,
      fixed: 'right',
      render: (text, record) => (
        <span className="text-center">
          <Switch onChange={() => this.handleSwitchChange(record)} checked={record.isSwitch} />
        </span>
      ),
    }];
    return data;
  }
  
  //====================== End Table ===============================//

  render() {
    return (
      <AdminLayout>
        <Layout>
          <div className="app-heading">
            <AdminContentHeader text="Thành phần lương" />
          </div>
          <div className="app-content">
            <div>
              <Table bordered size="middle" columns={this.columns()} dataSource={this.state.salaries} pagination={false} rowKey='id' />
            </div>
            <div className="clear"></div>
          </div>
          {
            this.state.visible ? (
              <AlertBox
                message={this.state.msgAlert}
                type={this.state.typeAlert}
              />
            ) : null
          }
        </Layout>
      </AdminLayout>
      
    );
  }
}

const mapStateToProps = state => ({
  store: state.salaries,
});
const mapDispatchToProps = dispatch => ({
  getStoreList: bindActionCreators(actSalaryList, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SalaryIndexs);
