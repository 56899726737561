import {ACTION_STORE_LIST} from 'actions/admin/store.js'
const initState = [];

const store = (state = initState, action) => {
  switch (action.type) {
    case ACTION_STORE_LIST:
      state = action.store.items;
      return [...state];
    default:
      return [...state]
  }
}

export default store;
