/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Layout, Icon, Col, Row, Popover, Avatar, Button } from 'antd';
import config from 'config.js'
const { Header } = Layout;



class AdminHeader extends Component {
  logout() {
    localStorage.removeItem('AccessToken');
    window.location.href = '/' + config.pathAdmin + 'log-out';
  }
  render() {
    let self = this;
    return (
      <Header className="menu-top">
        <Row style={{ height: '50px' }}>
          <Col span={8} style={{ height: '50px' }}>
            <div className="bg-top-logo">
              <img className="padding-left-8" alt='haravan-logo' style={{ height: '40px' }} src='/images/haravan.png' />
            </div>
          </Col>
          <Col span={8} className="text-center" style={{ height: '50px' }}>
          </Col>
          <Col className="text-right" span={8} style={{ height: '50px' }}>
            <div className="bg-top-login text-right">
              {/* <Popover placement="bottomRight" title={text} content={content} trigger="click"> */}
              <Popover placement="bottomRight" content={<Button block style={{ border: 0, fontWeight: 'bold' }} onClick={self.logout.bind(self)}>Đăng xuất</Button>} trigger="click">
                <Avatar style={{ top: '-2px', backgroundColor: '#2470b7' }} icon="user" />
                <a className="display-name" style={{ paddingLeft: '7px' }}>Admin  <Icon type="caret-down" /></a>
              </Popover>
            </div>
          </Col>
        </Row>
      </Header >
    );
  }
}

export default AdminHeader;

