import BaseModel from 'model/base.js';
const SalaryPeriod = {
  getList: (query, callback, callbackErr) => {
    let url = 'api/hara-work/salaryperiods';
    BaseModel.get(url, query, function(data) {
      callback(data.data);
    }, function(error) {
      callbackErr(error);
    });
  },
}
export default SalaryPeriod;