import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Spin, Icon } from 'antd';
import AdminMenuLeft from 'modules/admin/admin-menu-left.js'
import AdminHeader from 'modules/admin/admin-header.js'

const antIcon = <Icon type="loading-3-quarters" style={{ fontSize: 50 }} spin />;

class AdminLayout extends Component {
  text = () => <span>Thoát</span>
  content = () => (
    <div>
      <p>Thông tin cá nhân</p>
      <p>Đổi mật khẩu</p>
    </div>
  )

  componentDidMount() {
    
  }  
  render() {
    return (
      <Spin indicator={antIcon} spinning={false}>
        <Layout className="layout">
            {/*--------------- Start Menu Top --------------------*/}
              <AdminHeader/>
            {/*--------------- End Menu Top --------------------*/}
            <Layout>
              {/*--------------- Start Menu Left --------------------*/}
              <AdminMenuLeft />
              {/*--------------- End Menu Left --------------------*/}
              {/*--------------- Start Content --------------------*/}
              {this.props.children}
              {/*--------------- End Content --------------------*/}
            </Layout>
        </Layout>
      </Spin>
    )
  }
}
const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
