import BaseModel from 'model/base.js'
const StoreModel = {
  getList: (sucCallback, errCallback, finCallback) => {
    // let url = 'http://5bdec20c7ad6890013e9c2d0.mockapi.io/api/products';
    let url = 'api/stores/tch'
    BaseModel.get(url, null, function(data) {
      sucCallback(data.data);
    },
    err => errCallback(),
    () => finCallback())
  }
}

export default StoreModel;