import BaseModel from 'model/base.js'
const DepartmentModel = {
  getList: (sucCallback, errCallback, finCallback) => {
    let url = 'api/hara-work/departments?isGetAll=true'
    BaseModel.get(url, null, function(data) {
      sucCallback(data.data);
    },
    err => errCallback(err),
    () => finCallback())
  }
}

export default DepartmentModel;