import {ACTION_DEPARTMENT_LIST} from '../../actions/admin/department';

const initState = [];

const department = (state = initState, action) => {
  switch (action.type) {
    case ACTION_DEPARTMENT_LIST:
      state = action.department.items;
      return [...state];
    default:
      return [...state];
  }
}

export default department;