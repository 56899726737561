import BaseModel from 'model/base.js'

const apiUrl = 'api';

const BonusModel = {
  processBonus: (query, fromDate, toDate, salaryPeriodId,elementSaleTCH,elementServiceTCH,elementTMB,elementSaleTR,elementSaleSig,elementServiceSig, sucCallback, errCallback, finCallback) => {
    let url = `${apiUrl}/stores/sync-salary/range?from_date=`+fromDate+
    `&to_date=`+toDate+`&salaryPeriodId=`+salaryPeriodId
    +`&elementSaleTCH=`+elementSaleTCH
    +`&elementServiceTCH=`+elementServiceTCH
    +`&elementTMB=`+elementTMB
    +`&elementSaleTR=`+elementSaleTR
    +`&elementSaleSig=`+elementSaleSig
    +`&elementServiceSig=`+elementServiceSig;
    BaseModel.get(url, query, function (data) {
      sucCallback(data.data);
    },
      err => {
        errCallback(err)
      },
      () => finCallback())
  },
  checkRunSalary: (query, data, sucCallback, errCallback, finCallback) => {
    let url = `${apiUrl}/stores/checkrunsalary`;
    BaseModel.get(url, query, function (data) {
      sucCallback(data.data);
    },
      err => {
        errCallback(err)
      },
      () => finCallback())
  },
}

export default BonusModel;