import BaseModel from 'model/base.js'

const apiUrl = 'api';

const MappingModel = {
  getList: (query, sucCallback, errCallback, finCallback) => {
    let url = `${apiUrl}/stores/map`;
    BaseModel.get(url, query, function(data) {
      sucCallback(data.data);
    },
    err => {
      errCallback(err)
    },
    () => finCallback())
  },
  postMapping: (data, sucCallback, errCallback, finCallback) => {
    let url = `${apiUrl}/stores/map`;
    BaseModel.post(
      url, 
      data, 
      res => {
        sucCallback(res)
      }, 
      err => {
        errCallback(err)
      },
      () => finCallback()
    )
  },
  putMapping: (data, sucCallback, errCallback, finCallback) => {
    let url = `${apiUrl}/stores/map/update`;
    BaseModel.put(url, data, function(res) {
      sucCallback(res);
    },
    err => errCallback(err),
    () => finCallback())
  },
  deleteMapping: (tch_id, sucCallback, errCallback, finCallback) => {
    let url = `${apiUrl}/stores/map/${tch_id}`;
    BaseModel.delete(url, null, function(res) {
      sucCallback(res);
    },
    err => errCallback(err),
    () => finCallback())
  }
}

export default MappingModel;