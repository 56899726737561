import {
  ACTION_MAPPING_LIST,
  ACTION_MAPPING_ADD, 
  ACTION_MAPPING_EDIT, 
  ACTION_MAPPING_DELETE,
  ACTION_FILTER_MAPPING
} from '../../actions/admin/mapping';

const initState = {
  addedMapping: {},
  updatedMapping: {},
  deletedMapping: {},
  mappingList: []
};

const mapping = (state = initState, action) => {
  switch (action.type) {
    case ACTION_MAPPING_LIST: 
      state.mappingList = action.mapping.items;
      return state;
    case ACTION_MAPPING_ADD:
      state.addedMapping = action.mapping.data;
      return state;
    case ACTION_MAPPING_EDIT:
      state.updatedMapping = action.mapping;
      return state;
    case ACTION_MAPPING_DELETE: 
      state.deletedMapping = action.mapping;
      return state;
    case ACTION_FILTER_MAPPING:
      state.mappingList = action.mapping;
      return state;
    default:
      return state;
  }
}

export default mapping;