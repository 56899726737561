import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actStoreList } from 'actions/admin/store';
import lodash from 'lodash';
import { actDepartmentList } from '../../../../actions/admin/department';
import Select from 'antd/lib/select';
import PropTypes from 'prop-types';
import { Col, Tooltip } from 'antd';
// import { Pagination, Button } from 'antd';
import { Button } from 'antd';
import {Modal, Table, Divider } from 'antd';
import { Layout, Icon } from 'antd';
import AdminLayout from '../../admin-layout.js';
import AdminContentHeader from '../../admin-content-header.js';
import MappingModal from '../jobtitlelevelModal/jobtitlelevelModal';
import DepartmentModel from '../../../../model/department';
import AlertBox from '../../../../component/alertbox/alertbox';
import LoadingIndex from '../../loading/container/index';
import JobtitleLevelModel from '../../../../model/jobitleLevelModel';
import JobtitlelevelModal from '../jobtitlelevelModal/jobtitlelevelModal';
import {actAddJobtitleLevel, actEditJobtitleLevel, actListJobtitleLevel, actListJobtitle, actDeleteJobtitleLevel } from '../../../../actions/admin/jobtitleLevel';

const propTypes = {
  jobtitle: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  getJobtitleList: PropTypes.func.isRequired,
  actAddJobtitleLevel: PropTypes.func.isRequired,
  actEditJobtitleLevel: PropTypes.func.isRequired,
  actDeleteJobtitleLevel: PropTypes.func.isRequired,
  getJobtitleLevelList: PropTypes.func.isRequired
}




//======================= Select ===================================//
const Option = Select.Option;
const confirm = Modal.confirm;
// function handleChange(value) {
//   console.log(`selected ${value}`);
// }
function handleBlur() {
  // console.log('blur');
}
function handleFocus() {
  // console.log('focus');
}
//===================== End Select ================================//

//===================== Pagination ================================//
// function showTotal(total) {
//   return `Hiện thị ${total} trong tổng số ${total}`;
// }
// function onShowSizeChange(current, pageSize) {
//   // console.log(current, pageSize);
// }
//==================== End Pagination =============================//

class Jobtitlelevel extends Component {
  //===================== Start modal =============================//
  state = {
    ModalText: 'Content of the modal',
    visible: false,
    confirmLoading: false,
    isEditting: false,
    selectedStore: 0,
    selectedJobtitle: 0,
    selectedBrand: 0,
    selectedTypeBonus:0,
    inputLevel: null,
    filterStore: 0,
    filterJobtitle: 0,
    filterData: [],
    alertVisitble: false,
    typeAlert: 'success',
    msgAlert: 'Success',
    queryMapping: '',
    isLoadingData: true,
    jobtitles: [],
    jobtitleLevels: [],
    id_editing:null,
  }

  handleChangeSelectBrand= id => {
    this.setState({
      selectedBrand: id
    });
  }

  handleChangeSelectTypeBonus= id => {
    this.setState({
      selectedTypeBonus: id
    });
  }

  handleChangeSelectJobtitle = id => {
    this.setState({
      selectedJobtitle: id
    })
  }

  handlechangeInputLevel = value => {
    this.setState({
      inputLevel: value&&value.target?value.target.value:value
    })
  }

  handleChangeFilterJobtitle = id => {
    this.setState({
      filterJobtitle: id
    })
  }

  showModal = e => {
    if (e) {
      this.setState({
        isEditting: false
      })
    }
    this.setState({
      visible: true,
    });
  }

  changeIsEdittingStatus = () => {
    this.setState({
      isEditting: true
    })
  }
 showConfirm=(_id)=> {
   var _this=this;
        confirm({
          content: "Bạn có thực sự muốn xóa dòng này ?",
          title:"Thông báo",
          onOk() {
            _this.deleteJobtitleLevel(_id);
          },
          onCancel() {
            //console.log('Cancel');
          },
        });
  }
  //====================== Start Table ==============================//
  columns() {
    let data = [{
      title: 'Chức danh',
      dataIndex: 'jobtitleName',
      key: 'jobtitle_id',
      width:'35%'
    }, {
      title: 'Trọng số',
      dataIndex: 'level',
      key: 'level',
      width:'20%',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Tổ chức',
      dataIndex: 'brand',
      key: 'brand',
      width:'10%',
      render: text => <a>{text}</a>,
    }, {
      title: 'Loại thưởng',
      dataIndex: 'type_bonus',
      key: 'type_bonus',
      width:'25%',
      render: text => <a>{text == 1 ? "Thưởng doanh số" : text==2? "Thưởng dịch vụ":text==3?"Thưởng thuê mặt bằng":text==4?"Thưởng doanh số Signature":text==5?"Thưởng dịch vụ Signature":""}</a>,
    },{
      title: '',
      key: 'action',
      width:'5%',
      render: (text, record) => (
        <span className="text-center">
          <Tooltip placement="left" title="Xoá">
            <span
              onClick={() => this.showConfirm(record._id)}
              style={{ color: '#1890ff', cursor: 'pointer' }}
            >
              <Icon type="delete" />
            </span>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip placement="left" title="Chỉnh sửa">
            <span
              onClick={() => {
                this.showModal();
                this.changeIsEdittingStatus();
                this.handleChangeSelectJobtitle(record.jobtitle_id);
                this.handleChangeSelectBrand(record.brand);
                this.handleChangeSelectTypeBonus(record.type_bonus);
                this.handlechangeInputLevel(record.level);
                this.handleSaveIdEditing(record._id);
              }}
              style={{ color: '#1890ff', cursor: 'pointer' }}
            >
              <Icon type="edit" theme="twoTone" />
            </span>
          </Tooltip>

        </span>
      ),
    }];
    return data;
  }
  filterData = () => {
    const { mapping, store, department } = this.props;

    let filteredMapping = mapping.mappingList ? mapping.mappingList : [];

    const data = filteredMapping.map(m => {
      let storeMap = lodash.find(store, { 'id': m.store_id, 'brand': m.brand });
      let depart = lodash.find(department, ['id', m.hr_department_id]);
      let data = {
        key: `${m._id}${m.hr_department_id}`,
        tch_id: m.store_id,
        brand: m.brand,
        _id: m._id,
        hr_department_id: m.hr_department_id,
        storeName: lodash.get(storeMap, 'name', '') + ' - [' + lodash.get(storeMap, 'brand', '') + ']',
        departmentName: lodash.get(depart, 'name', '') + ' - [' + lodash.get(depart, 'parentName', '') + ']'
      };

      return data;
    });

    this.setState({
      filterData: data
    })
  }

  //==================================== End Table ==============================//

  handleOk = () => {
    const {id_editing, selectedBrand, selectedJobtitle,inputLevel,selectedTypeBonus } = this.state;
    if(selectedJobtitle==null||selectedJobtitle==0){
      this.setState({
        visible:true,
        alertVisitble: true,
        msgAlert: 'Vui lòng chọn chức danh.',
        typeAlert: 'error'
      },()=>setTimeout(() => this.setState({
        alertVisitble: false,
      }), 3000));
      return;
    }
    if(inputLevel==null||inputLevel==""){
      this.setState({
        visible:true,
        alertVisitble: true,
        msgAlert: 'Vui lòng nhập trọng số.',
        typeAlert: 'error'
      },()=>setTimeout(() => this.setState({
        alertVisitble: false,
      }), 3000));
      return;
    }
    if(selectedBrand==null||selectedBrand==0){
      this.setState({
        visible:true,
        alertVisitble: true,
        msgAlert: 'Vui lòng nhánh cửa hàng.',
        typeAlert: 'error'
      },()=>setTimeout(() => this.setState({
        alertVisitble: false,
      }), 3000));
      return;
    }
    if(inputLevel < 0){
      this.setState({
        visible:true,
        alertVisitble: true,
        msgAlert: 'Trọng số phải lớn hơn hoặc bằng 0.',
        typeAlert: 'error'
      },()=>setTimeout(() => this.setState({
        alertVisitble: false,
      }), 3000));
      return;
    }
    if(selectedTypeBonus==null||selectedTypeBonus==0){
      this.setState({
        visible:true,
        alertVisitble: true,
        msgAlert: 'Vui lòng chọn loại thưởng.',
        typeAlert: 'error'
      },()=>setTimeout(() => this.setState({
        alertVisitble: false,
      }), 3000));
      return;
    }
    let object={_id:id_editing, jobtitle_id:selectedJobtitle,level:inputLevel,brand:selectedBrand,type_bonus:selectedTypeBonus}
    if (!this.state.isEditting) {
      this.postJobtitleLevel(object);
    } else {
      this.putJobtitleLevel(object);
    }

    this.setState({
      ModalText: 'The modal will be closed after success',
      confirmLoading: true,
    });
  }

  handleCancel = () => {
    console.log('Clicked cancel button');
    this.setState({
      visible: false,
      selectedJobtitle: 0,
      selectedBrand: 0,
      inputLevel:0,
      selectedTypeBonus:0,
      id_editing:null,
    });
  }
  //====================== End Modal ===============================//
  resetFilter=()=>{
    this.setState({filterJobtitle:0},()=>this.getListJobtitleLevels())
  }

  getListJobtitleLevels = () => {
    let _this = this;
    _this.setState({
      isLoadingData: true
    });
    JobtitleLevelModel.getList({jobtitle_id:_this.state.filterJobtitle==0?null:_this.state.filterJobtitle},function (data) {
      var dataRe = data.items.map(a => {
        var jobtitle = _this.state.jobtitles.filter(b => b.id == a.jobtitle_id)[0]
        a.jobtitleName = jobtitle ? jobtitle.name : '';
        return a;
      })
      _this.setState({ jobtitleLevels: dataRe,isLoadingData:false });
    },
      err => {
        _this.setState({
          alertVisitble: true,
          typeAlert: 'error',
          msgAlert: `${err}`
        })
      },
    () => {
      if (_this.state.alertVisitble === true) {
        setTimeout(() => _this.setState({
          alertVisitble: false
        }), 3000)
      }
      _this.setState({
        isLoadingData: false
      })
    });
  }

  getDepartments = errStore => {
    let _this = this;
    DepartmentModel.getList(data => {
      this.props.getDepartmentList(data);
      _this.getMapping(errStore, null);
    },
      err => {
        this.setState({
          alertVisitble: true,
          typeAlert: 'error',
          msgAlert: `${err}`
        });
        //_this.getMapping(errStore, err);
      },
      () => { });
  };

  getJobtitles = () => {
    let _this=this;
    JobtitleLevelModel.getListJobtitle(data => {
      _this.setState({jobtitles:data.items})
      _this.props.getJobtitleList(data)
      _this.getListJobtitleLevels();
    },
      err => {
        this.setState({
          alertVisitble: true,
          typeAlert: 'error',
          msgAlert: `${err}`
        });
        //      this.getMapping(errStore, err);
      },
      () => { });
  }

  parseSelectStore = (data) => {
    let arrFilter = data.split("#");
    return arrFilter;
  }
  formatFilter = () => {
    const { filterStore, filterDepartment } = this.state;
    let filter = {};  
    if (filterStore) {
      [filter.store_id, filter.brand] = this.parseSelectStore(filterStore);
    }

    if (filterDepartment) {
      filter.hr_department_id = filterDepartment
    }
    return filter
  };
  getMapping = (errStore, errDepartment) => {
    const _this = this;
    let filter = this.formatFilter();
    JobtitlelevelModal.getList(filter, data => {
      _this.props.getMappingList(data);
      if (errStore || errDepartment) {
        // if (errDepartment) {
        _this.setState({
          alertVisitble: true,
          typeAlert: 'error',
          msgAlert: errStore && errDepartment
            ? `Stores list: ${errStore}. Departments list: ${errDepartment}`
            : errStore && !errDepartment
              ? `Stores list: ${errStore}`
              : `Departments list: ${errDepartment}`
        })
      }
    },
      err => {
        _this.setState({
          alertVisitble: true,
          typeAlert: 'error',
          msgAlert: errStore && errDepartment
            ? `Mapping list: ${err}. Stores list: ${errStore}. Departments list: ${errDepartment}`
            : errStore && !errDepartment
              ? `Mapping list: ${err}. Stores list: ${errStore}`
              : errDepartment && !errStore
                ? `Mapping list: ${err}. Stores list: ${errDepartment}`
                : `Mapping list: ${err}`
        })
      },
      () => {
        if (_this.state.alertVisitble === true) {
          setTimeout(() => _this.setState({
            alertVisitble: false
          }), 3000)
        }
        _this.filterData();
        _this.setState({
          isLoadingData: false
        })
      });
  }

  postJobtitleLevel = jobtitleModel => {
    let _this = this;
    JobtitleLevelModel.postJobtitleLevel(

      jobtitleModel,
      data => {
        _this.setState({
          visible: false,
          selectedBrand: 0,
          selectedJobtitle: 0,
          selectedTypeBonus:0,
          inputLevel:null,
          id_editing:null,
          alertVisitble: true,
          msgAlert: 'Thêm Trọng số thành công',
          typeAlert: 'success'
        });
      },
      err => {
        let message = lodash.get(err, 'response.data.message', []);
        message = message.join("<br>");
        _this.setState({
          alertVisitble: true,
          msgAlert: `Thêm trọng số thất bại. Chi tiết: ${message}`,
          typeAlert: 'error'
        })
      },
      () => {
        _this.setState({
          confirmLoading: false,
        });
        _this.getListJobtitleLevels();
      }
    )
  }

  handleSaveIdEditing = id =>{
    this.setState({id_editing : id})
  }

  putJobtitleLevel = jobtitleModel => {
    let _this = this;
    JobtitleLevelModel.putJobtitleLevel(
      jobtitleModel,
      data => {
        _this.props.actEditJobtitleLevel(data);
        this.setState({
          visible: false,
          selectedBrand: 0,
          selectedJobtitle: 0,
          inputLevel:null,
          id_editing:null,
          alertVisitble: true,
          msgAlert: 'Sửa trọng số thành công',
          typeAlert: 'success'
        })
      },
      err => {
        _this.setState({
          alertVisitble: true,
          msgAlert: `Sửa trọng số thất bại. Chi tiết: ${err.response.data.message.join()}`,
          typeAlert: 'error'
        })
      },
      () => {
        _this.setState({
          confirmLoading: false
        });
        _this.getListJobtitleLevels();
      }
    )
  }

  deleteJobtitleLevel = id => {
    let _this = this;
    JobtitleLevelModel.deleteJobtitleLevel(id, data => {
      _this.setState({
        alertVisitble: true,
        msgAlert: 'Xóa Trọng số thành công',
        typeAlert: 'success'
      })
    },
      err => {
        _this.setState({
          alertVisitble: true,
          msgAlert: `Xóa Trọng số thất bại. Chi tiết: ${err}`,
          typeAlert: 'error'
        })
      },
      () => {
        _this.getListJobtitleLevels();
      })
  }

  componentDidMount() {
    this.getJobtitles();
    // this.getMapping();
  }

  componentDidUpdate() {
  }

  render() {
    const { jobtitleLevel } = this.props;
    const {
      visible,
      confirmLoading,
      isEditting,
      filterData,
      alertVisitble,
      typeAlert,
      msgAlert,
      selectedBrand,
      selectedJobtitle,
      inputLevel,
      isLoadingData
    } = this.state;
    return (
      <>
        {isLoadingData &&
          <LoadingIndex />
        }
        {!isLoadingData &&
          <AdminLayout>
            <Layout>
              <div className="app-heading">
                <AdminContentHeader text="Thiết lập trọng số chức danh." />
                <Col className="gutter-row" span={12}>
                  <div className="gutter-box text-right">
                    <Button onClick={this.showModal} type="primary" ghost><Icon type="plus-circle" theme="twoTone" /> Thêm</Button>
                  </div>
                </Col>
              </div>
              <div className="app-content">
                <Col className="gutter-row" span={6}>
                  <div className="gutter-box margin-right-15">
                    <Select
                      showSearch
                      className="width-100-per"
                      placeholder="Tất cả"
                      defaultValue={this.state.filterJobtitle==0?undefined:this.state.filterJobtitle}
                      optionFilterProp="children"
                      onChange={this.handleChangeFilterJobtitle}
                      onFocus={handleFocus} 
                      onBlur={handleBlur}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {this.state.jobtitles && this.state.jobtitles.map(d =>
                        <Option key={d.id} value={d.id}>{d.name}</Option>
                      )}
                    </Select>
                  </div>
                </Col>
                <Col className="gutter-row" span={3}>
                  <div className="gutter-box">
                    <Button type="default" onClick= {() => this.resetFilter()}><Icon type="filter" theme="twoTone" /> Đặt lại bộ lọc</Button>
                  </div>
                </Col>
                <Col className="gutter-row" span={6}>
                  <div className="gutter-box">
                    <Button type="primary" onClick={() => this.getListJobtitleLevels()}><Icon type="filter" theme="twoTone" /> Áp dụng bộ lọc</Button>
                  </div>
                </Col>
                <div className="clear"></div>
              </div>

              <div className="app-content">
                <div>
                  <Table bordered size="middle" locale={{emptyText: 'Không có dữ liệu.'}} columns={this.columns()} dataSource={this.state.jobtitleLevels} pagination={false} />
                </div>
                <div className="clear"></div>
              </div>

              <JobtitlelevelModal
                jobtitle={this.state.jobtitles}
                jobtitleLevel={jobtitleLevel}
                visible={visible}
                confirmLoading={confirmLoading}
                handleOk={this.handleOk}
                handleCancel={this.handleCancel}
                isEditting={isEditting}
                handleChangeSelectJobtitle={this.handleChangeSelectJobtitle}
                handleChangeSelectBrand={this.handleChangeSelectBrand}
                handlechangeInputLevel={this.handlechangeInputLevel}
                selectedJobtitle={selectedJobtitle}
                selectedBrand={selectedBrand}
                handleSaveIdEditing={this.handleSaveIdEditing}
                handleSelectedTypeBonus={this.handleChangeSelectTypeBonus}
                selectedTypeBonus={this.state.selectedTypeBonus}
                inputLevel={inputLevel}
              />

              {alertVisitble &&
                <AlertBox
                  message={msgAlert}
                  type={typeAlert}
                />
              }
            </Layout>
          </AdminLayout>
        }
      </>
    );
  }
}

Jobtitlelevel.propTypes = propTypes;
const jobtitleLevelStateToProps = state => ({
  jobtitleLevel: state.jobtitleLevel,
  jobtitle: state.jobtitle,
});
const jobtitleLevelDispatchToProps = dispatch => ({
  getJobtitleLevelList: bindActionCreators(actListJobtitleLevel, dispatch),
  getJobtitleList: bindActionCreators(actListJobtitle, dispatch),
  actAddJobtitleLevel: bindActionCreators(actAddJobtitleLevel, dispatch),
  actEditJobtitleLevel: bindActionCreators(actEditJobtitleLevel, dispatch),
  actDeleteJobtitleLevel: bindActionCreators(actDeleteJobtitleLevel, dispatch),
});

export default connect(jobtitleLevelStateToProps, jobtitleLevelDispatchToProps)(Jobtitlelevel);
